import { WebStorageStateStore } from "oidc-client-ts"
import { AuthProviderProps } from "react-oidc-context"

type EnvironmentStage = "development" | "ci" | "staging" | "live"

export interface NGWebConfigWireType {
    environmentStage: EnvironmentStage
    oidcEndpointURL: string
    oidcClientID: string
    graphqlEndpointURL: string
    graphqlSubscriptionURL: string
    authenticationLoginURL: string
    authenticationLogoutURL: string
    authenticationForgotPasswordURL: string
}

export class NGWebConfigHelper {
    constructor(private config: NGWebConfigWireType) {}

    public get graphqlEndpointURL(): string {
        return this.config.graphqlEndpointURL
    }

    // Generates the OIDC logout redirect URL
    // See: https://docs.aws.amazon.com/cognito/latest/developerguide/logout-endpoint.html
    // Example:
    //   GET https://mydomain.auth.us-east-1.amazoncognito.com/logout?
    //   client_id=ad398u21ijw3s9w3939&
    //   logout_uri=https://myclient/logout
    public get logoutURL(): string {
        return `${this.config.authenticationLogoutURL}?client_id=${this.config.oidcClientID}&logout_uri=${this.redirectLoggedOutURL}&redirect_uri=${this.redirectLoggedInURL}&response_type=code`
    }

    public get forgotPasswordURL(): string {
        return `${this.config.authenticationForgotPasswordURL}?client_id=${this.config.oidcClientID}&logout_uri=${this.redirectLoggedOutURL}&redirect_uri=${this.redirectLoggedInURL}&response_type=code`
    }

    public get oidcStorageKey(): string {
        return `oidc.user:${this.config.oidcEndpointURL}/:${this.config.oidcClientID}`
    }

    public get redirectLoggedInURL(): string {
        return `${window.location.origin}/dashboard`
    }

    public get redirectLoggedOutURL(): string {
        return `${window.location.origin}/logout`
    }

    public get oidcClientID(): string {
        return this.config.oidcClientID
    }

    public get authProviderProps(): AuthProviderProps {
        return {
            authority: `${this.config.oidcEndpointURL}/`,
            client_id: this.config.oidcClientID,
            redirect_uri: this.redirectLoggedInURL,
            scope: "openid aws.cognito.signin.user.admin",
            onSigninCallback: (user): void => {
                if (user && user.state && user.state !== "/dashboard") {
                    const nestedUrl = user.state as string
                    // Redirect to nested url
                    return window.location.replace(nestedUrl)
                } else {
                    // Remove code=exampleCode - from url. This does not trigger a redirect
                    window.history.replaceState(
                        {},
                        "",
                        window.location.pathname
                    )
                }
            },
            automaticSilentRenew: true,
            metadataSeed: {
                end_session_endpoint: this.logoutURL,
            },
            userStore: new WebStorageStateStore({
                store: window.localStorage,
            }),
        }
    }
}

export const DefaultConfig: NGWebConfigWireType = {
    environmentStage: "development",
    oidcEndpointURL: "http://localhost:10000",
    oidcClientID: "localdev",
    // graphqlEndpointURL currently pointed at ngapi auth-proxy, change me
    graphqlEndpointURL: "http://localhost:3000/graphql",
    graphqlSubscriptionURL: "do not use me",
    authenticationLoginURL: "http://localhost:10000/interaction/{uid}/login",
    authenticationLogoutURL: "http://localhost:10000/session/end",
    authenticationForgotPasswordURL: "",
}
