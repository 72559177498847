import React, { Suspense, lazy } from "react"
import ReactDOM from "react-dom/client"
import "./index.scss"
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom"
import { AuthProvider } from "react-oidc-context"
import { ConfigContext } from "./context/ConfigContexts"
import {
    NGWebConfigWireType,
    NGWebConfigHelper,
    DefaultConfig,
} from "./configuration/configuration"
import Axios from "axios"
import { CreateClient } from "./createClient"
import { ApolloProvider } from "@apollo/client"
import { AddWellsProvider } from "./context/addWellsContext"
import Login from "./components/login"
import TrexParentRoute from "./components/routeContainers/TrexParentRoute"
import PrivateAuthenticatedRoutes from "./components/routeContainers/PrivateAuthenticatedRoutes"
import LoggedOut from "./components/logout"
import Modal from "./components/shared/modalContainer"
import WellControls from "./components/wellControlsMenu/wellControls"
import AddWells from "./components/addWellsModal"
import WellControlsLink from "./components/wellControlsMenu/wellControlsLink"
import WellControlsUnlink from "./components/wellControlsMenu/wellControlsUnlink"
import { WellControlsProvider } from "./context/wellControlsContext"
import { ViewerProvider } from "./context/ViewerContext"
import UsersTable from "./pages/admin/users"
import OrganizationsTable from "./pages/admin/organizations"
import UpdateUserModal from "./components/updateUserModal"
import AddOrganizationModal from "./components/addOrganizationsModal"
import InviteUsersModal from "./components/InviteUsersModal"
import UpdateOrganizationModal from "./components/updateOrganizationModal"
import RoleBasedProtectedRoute from "./components/routeContainers/RoleBasedProtectedRoute"
import { errorLogger } from "./util/errorLogger"
import RootLevelErrorBoundary from "./components/errors/rootLevelErrorBoundary"
import { ErrorBoundary } from "react-error-boundary"
import SuspensePageFallBack from "./components/shared/suspensePageFallBack"
import IwpWellDetailsChart from "./components/individualWellPageComponents/wellDetailsChart"
import WellAttributes from "./components/individualWellPageComponents/wellAttributes"
import UpdateWellAttributes from "./components/individualWellPageComponents/updateWellAttributesModal"
import UpdateWellWiringModeModal from "./components/individualWellPageComponents/updateWellWiringModeModal"
import UserProfileSettings from "./pages/userProfileSettings"
import UpdateProfileModal from "./components/userProfileComponents/updateProfileModal"
import WellIndexPage from "./pages/wellIndex"
import AlertRules from "./components/notifications/rules"
import AddAlertRule from "./components/notifications/rules/manageAlertRule/addAlertRule/index"
import UpdateAlertRule from "./components/notifications/rules/manageAlertRule/updateAlertRule"
import DeleteRule from "./components/notifications/rules/manageAlertRule/deleteRule"
import { BreakPointContextProvider } from "./context/breakPointContext"
import UpdateDoNotDisturb from "./components/userProfileComponents/updateDoNotDisturb"
import UpdateProfileEmail from "./components/userProfileComponents/updateProfileEmailModal"
import UpdateProfilePhoneNumber from "./components/userProfileComponents/updatePhoneNumberModal"
import MySubscriptions from "./components/notifications/subscriptions/mySubscriptions"
import SubscriptionByRule from "./components/notifications/subscriptions/adminSubscriptionsViews/subscriptionsByRule"
import SubscriptionByUser from "./components/notifications/subscriptions/adminSubscriptionsViews/subscriptionsByUser"
import { VerifyAttributeModal } from "./components/userProfileComponents/verifyAttributeModal"
import { NotificationsContextProvider } from "./components/notifications/context/notificationsContext"
import UpdateSubscription from "./components/notifications/subscriptions/manageSubscriptions/updateSubscriptions"
import DeleteSubscription from "./components/notifications/subscriptions/manageSubscriptions/deleteSubscriptions"
import ManageSubsriptionSilence from "./components/notifications/subscriptions/manageSilence"
import CopySubscriptionToUser from "./components/notifications/subscriptions/adminSubscriptionsViews/copySubscriptionsToUser"
import MoveSubscriptionToUser from "./components/notifications/subscriptions/adminSubscriptionsViews/moveSubscriptionsToUser"
import AddSubscription from "./components/notifications/subscriptions/manageSubscriptions/addSubscriptions"
import RemoveSilenceSubscription from "./components/notifications/subscriptions/manageSilence/removeSilence"
import MyNotifications from "./components/notifications/notifications/myNotifications"
import { AppAlertBannerProvider } from "./context/alertBannerContext"
import UpdateProfileTimezone from "./components/userProfileComponents/updateProfileTimezoneModal"

// Lazy loading pages
const IndividualWellPage = lazy(() => import("./pages/individualWellPage"))
const NotificationsPage = lazy(() => import("./pages/notifications"))

// url constants
// abs route (absolute route) = "/route"
// invite user
export const INVITE_USER_ROUTE = "inviteUser"

// notifications
export const NOTIFICATIONS_ABS_ROUTE = "/notifications"

// my notifications
export const MY_NOTIFICATIONS_CHILD_ROUTE = "myNotifications"
export const MY_NOTIFICATIONS_ABS_ROUTE = `${NOTIFICATIONS_ABS_ROUTE}/${MY_NOTIFICATIONS_CHILD_ROUTE}`

// subscriptions
export const NOTIFICATIONS_SUBSCRIPTION_CHILD_ROUTE = "subscriptions"
export const NOTIFICATIONS_SUBSCRIPTION_BY_RULE_CHILD = "subscriptionsByRule"
export const NOTIFICATIONS_SUBSCRIPTION_BY_USER_CHILD = "subscriptionsByUser"
export const NOTIFICATIONS_SUBSCRIPTION_ABS_ROUTE = `${NOTIFICATIONS_ABS_ROUTE}/${NOTIFICATIONS_SUBSCRIPTION_CHILD_ROUTE}`
export const NOTIFICATIONS_SUBSCRIPTION_BY_RULE_ABS_ROUTE = `${NOTIFICATIONS_ABS_ROUTE}/${NOTIFICATIONS_SUBSCRIPTION_BY_RULE_CHILD}`
export const NOTIFICATIONS_SUBSCRIPTION_BY_USER_ABS_ROUTE = `${NOTIFICATIONS_ABS_ROUTE}/${NOTIFICATIONS_SUBSCRIPTION_BY_USER_CHILD}`

export const NOTIFICATIONS_SUBSCRIPTION_ADD_SUBSCRIPTION_CHILD = "add"
export const NOTIFICATIONS_ADMIN_ADD_SUBSCRIPTION_CHILD = "adminAdd"
export const NOTIFICATIONS_SUBSCRIPTION_UPDATE_SUBSCRIPTION_CHILD = "update"
export const NOTIFICATIONS_ADMIN_UPDATE_SUBSCRIPTION_CHILD = "adminUpdate"
export const NOTIFICATIONS_DELETE_SUBSCRIPTION_CHILD = "delete"
export const NOTIFICATIONS_ADMIN_DELETE_SUBSCRIPTION_CHILD = "adminDelete"
export const NOTIFICATIONS_SILENCE_CHILD = "silence"
export const NOTIFICATIONS_ADMIN_SILENCE_CHILD = "adminSilence"
export const NOTIFICATIONS_REMOVE_SILENCE_CHILD = "removeSilence"
export const NOTIFICATIONS_ADMIN_REMOVE_SILENCE_CHILD = "adminRemoveSilence"

// rules
export const NOTIFICATIONS_RULES_CHILD_ROUTE = "rules"
export const NOTIFICATIONS_RULES_ABS_ROUTE = `${NOTIFICATIONS_ABS_ROUTE}/${NOTIFICATIONS_RULES_CHILD_ROUTE}`
export const NOTIFICATIONS_RULES_UPDATE_RULE_ABS_ROUTE = (ruleId: string) => {
    return `${NOTIFICATIONS_RULES_ABS_ROUTE}/${ruleId}/updateRule`
}

// user settings
export const USER_SETTINGS_ABS_ROUTE = "/userSettings"
export const EDIT_DND_CHILD_ROUTE = "editDoNotDisturb"
export const EDIT_PROFILE_CHILD_ROUTE = "editProfile"
export const EDIT_EMAIL_CHILD_ROUTE = "editEmail"
export const EDIT_PHONE_NUMBER_CHILD_ROUTE = "editPhoneNumber"
export const EDIT_TIMEZONE_CHILD_ROUTE = "editTimezone"
export const USER_SETTINGS_EDIT_PROFILE_ABS_ROUTE = `${USER_SETTINGS_ABS_ROUTE}/${EDIT_PROFILE_CHILD_ROUTE}`
export const USER_SETTINGS_EDIT_DND_ABS_ROUTE = `${USER_SETTINGS_ABS_ROUTE}/${EDIT_DND_CHILD_ROUTE}`
export const USER_SETTINGS_EDIT_EMAIL_ABS_ROUTE = `${USER_SETTINGS_ABS_ROUTE}/${EDIT_EMAIL_CHILD_ROUTE}`
export const USER_SETTINGS_EDIT_PHONE_NUMBER_ABS_ROUTE = `${USER_SETTINGS_ABS_ROUTE}/${EDIT_PHONE_NUMBER_CHILD_ROUTE}`
export const USER_SETTINGS_EDIT_TIMEZONE_ABS_ROUTE = `${USER_SETTINGS_ABS_ROUTE}/${EDIT_TIMEZONE_CHILD_ROUTE}`
export const USER_SETTINGS_VERIFY_ABS_ROUTE = `${USER_SETTINGS_ABS_ROUTE}/verifyAttribute`

async function GetConfig(): Promise<NGWebConfigHelper> {
    try {
        const response = await Axios.get<NGWebConfigWireType>("/config.json")
        return new NGWebConfigHelper(response.data)
    } catch (error) {
        const defaultConfig = DefaultConfig
        const helper = new NGWebConfigHelper(defaultConfig)
        console.warn(
            `failed to fetch config, using default config: ${error}`,
            defaultConfig
        )
        return Promise.resolve(helper)
    }
}

async function Main() {
    const root = ReactDOM.createRoot(
        document.getElementById("root") as HTMLElement
    )

    const config = await GetConfig()
    const client = await CreateClient(config)

    const trexRouter = createBrowserRouter([
        {
            path: "/",
            element: <Navigate to="/dashboard" replace />,
        },
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "/logout",
            element: <LoggedOut />,
        },
        {
            element: (
                <PrivateAuthenticatedRoutes>
                    <ErrorBoundary
                        onError={errorLogger}
                        fallbackRender={RootLevelErrorBoundary}
                    >
                        <BreakPointContextProvider>
                            <AppAlertBannerProvider>
                                <ViewerProvider>
                                    <RoleBasedProtectedRoute>
                                        <TrexParentRoute />
                                    </RoleBasedProtectedRoute>
                                </ViewerProvider>
                            </AppAlertBannerProvider>
                        </BreakPointContextProvider>
                    </ErrorBoundary>
                </PrivateAuthenticatedRoutes>
            ),
            children: [
                {
                    path: "/dashboard",
                    element: <WellIndexPage />,
                    children: [
                        {
                            path: "controls/:wellId/edit",
                            element: (
                                <Modal>
                                    <WellControlsProvider>
                                        <WellControls />
                                    </WellControlsProvider>
                                </Modal>
                            ),
                        },
                        {
                            path: "controls/:wellId/link",
                            element: (
                                <Modal>
                                    <WellControlsLink />
                                </Modal>
                            ),
                        },
                        {
                            path: "controls/:wellId/unlink",
                            element: (
                                <Modal>
                                    <WellControlsUnlink />
                                </Modal>
                            ),
                        },
                        {
                            path: "addWells",
                            element: (
                                <Modal>
                                    <AddWellsProvider>
                                        <AddWells />
                                    </AddWellsProvider>
                                </Modal>
                            ),
                        },
                        {
                            path: "inviteUser",
                            element: (
                                <Modal>
                                    <InviteUsersModal />
                                </Modal>
                            ),
                        },
                    ],
                },
                {
                    path: USER_SETTINGS_ABS_ROUTE,
                    element: <UserProfileSettings />,
                    children: [
                        {
                            path: USER_SETTINGS_EDIT_PROFILE_ABS_ROUTE,
                            element: (
                                <Modal>
                                    <UpdateProfileModal />
                                </Modal>
                            ),
                        },
                        {
                            path: USER_SETTINGS_EDIT_DND_ABS_ROUTE,
                            element: (
                                <Modal>
                                    <UpdateDoNotDisturb />
                                </Modal>
                            ),
                        },
                        {
                            path: USER_SETTINGS_EDIT_EMAIL_ABS_ROUTE,
                            element: (
                                <Modal>
                                    <UpdateProfileEmail />
                                </Modal>
                            ),
                        },
                        {
                            path: USER_SETTINGS_EDIT_PHONE_NUMBER_ABS_ROUTE,
                            element: (
                                <Modal>
                                    <UpdateProfilePhoneNumber />
                                </Modal>
                            ),
                        },
                        {
                            path: USER_SETTINGS_EDIT_TIMEZONE_ABS_ROUTE,
                            element: (
                                <Modal>
                                    <UpdateProfileTimezone />
                                </Modal>
                            ),
                        },
                        {
                            path: INVITE_USER_ROUTE,
                            element: (
                                <Modal>
                                    <InviteUsersModal />
                                </Modal>
                            ),
                        },
                        {
                            path: USER_SETTINGS_VERIFY_ABS_ROUTE,
                            element: (
                                <Modal>
                                    <VerifyAttributeModal />
                                </Modal>
                            ),
                        },
                    ],
                },
                {
                    element: (
                        <Suspense fallback={<SuspensePageFallBack />}>
                            <IndividualWellPage />
                        </Suspense>
                    ),
                    children: [
                        {
                            path: "/wellDetails/:wellId",
                            element: <IwpWellDetailsChart />,
                            children: [
                                {
                                    path: "controls/edit",
                                    element: (
                                        <Modal>
                                            <WellControlsProvider>
                                                <WellControls />
                                            </WellControlsProvider>
                                        </Modal>
                                    ),
                                },
                                {
                                    path: "controls/link",
                                    element: (
                                        <Modal>
                                            <WellControlsLink />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: "controls/unlink",
                                    element: (
                                        <Modal>
                                            <WellControlsUnlink />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: "inviteUser",
                                    element: (
                                        <Modal>
                                            <InviteUsersModal />
                                        </Modal>
                                    ),
                                },
                            ],
                        },
                        {
                            path: "/wellAttributes/:wellId",
                            element: <WellAttributes />,
                            children: [
                                {
                                    path: "edit",
                                    element: (
                                        <Modal>
                                            <UpdateWellAttributes />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: "editInstallation",
                                    element: (
                                        <Modal>
                                            <UpdateWellWiringModeModal />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: "controls/edit",
                                    element: (
                                        <Modal>
                                            <WellControlsProvider>
                                                <WellControls />
                                            </WellControlsProvider>
                                        </Modal>
                                    ),
                                },
                                {
                                    path: "controls/link",
                                    element: (
                                        <Modal>
                                            <WellControlsLink />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: "controls/unlink",
                                    element: (
                                        <Modal>
                                            <WellControlsUnlink />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: "inviteUser",
                                    element: (
                                        <Modal>
                                            <InviteUsersModal />
                                        </Modal>
                                    ),
                                },
                            ],
                        },
                    ],
                },
                {
                    element: (
                        <Suspense fallback={<SuspensePageFallBack />}>
                            <NotificationsContextProvider>
                                <NotificationsPage />
                            </NotificationsContextProvider>
                        </Suspense>
                    ),
                    children: [
                        {
                            path: MY_NOTIFICATIONS_ABS_ROUTE,
                            element: <MyNotifications />,
                            children: [
                                {
                                    path: "inviteUser",
                                    element: (
                                        <Modal>
                                            <InviteUsersModal />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_SUBSCRIPTION_UPDATE_SUBSCRIPTION_CHILD}`,
                                    element: (
                                        <Modal>
                                            <UpdateSubscription
                                                viewerAction={"self"}
                                            />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_DELETE_SUBSCRIPTION_CHILD}`,
                                    element: (
                                        <Modal>
                                            <DeleteSubscription viewerAction="self" />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_SILENCE_CHILD}`,
                                    element: (
                                        <Modal>
                                            <ManageSubsriptionSilence viewerAction="self" />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_REMOVE_SILENCE_CHILD}`,
                                    element: (
                                        <Modal>
                                            <RemoveSilenceSubscription viewerAction="self" />
                                        </Modal>
                                    ),
                                },
                            ],
                        },
                        {
                            path: NOTIFICATIONS_SUBSCRIPTION_ABS_ROUTE,
                            element: <MySubscriptions />,
                            children: [
                                {
                                    path: "inviteUser",
                                    element: (
                                        <Modal>
                                            <InviteUsersModal />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: NOTIFICATIONS_SUBSCRIPTION_ADD_SUBSCRIPTION_CHILD,
                                    element: (
                                        <Modal>
                                            <AddSubscription
                                                viewerAction={"self"}
                                            />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_SUBSCRIPTION_UPDATE_SUBSCRIPTION_CHILD}`,
                                    element: (
                                        <Modal>
                                            <UpdateSubscription
                                                viewerAction={"self"}
                                            />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_DELETE_SUBSCRIPTION_CHILD}`,
                                    element: (
                                        <Modal>
                                            <DeleteSubscription viewerAction="self" />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_SILENCE_CHILD}`,
                                    element: (
                                        <Modal>
                                            <ManageSubsriptionSilence viewerAction="self" />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_REMOVE_SILENCE_CHILD}`,
                                    element: (
                                        <Modal>
                                            <RemoveSilenceSubscription viewerAction="self" />
                                        </Modal>
                                    ),
                                },
                            ],
                        },
                        {
                            path: NOTIFICATIONS_SUBSCRIPTION_BY_RULE_ABS_ROUTE,
                            element: <SubscriptionByRule />,
                            children: [
                                {
                                    path: "inviteUser",
                                    element: (
                                        <Modal>
                                            <InviteUsersModal />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: NOTIFICATIONS_ADMIN_ADD_SUBSCRIPTION_CHILD,
                                    element: (
                                        <Modal>
                                            <AddSubscription
                                                viewerAction={"admin"}
                                            />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_ADMIN_UPDATE_SUBSCRIPTION_CHILD}`,
                                    element: (
                                        <Modal>
                                            <UpdateSubscription
                                                viewerAction={"admin"}
                                            />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_ADMIN_DELETE_SUBSCRIPTION_CHILD}`,
                                    element: (
                                        <Modal>
                                            <DeleteSubscription viewerAction="admin" />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_ADMIN_SILENCE_CHILD}`,
                                    element: (
                                        <Modal>
                                            <ManageSubsriptionSilence viewerAction="admin" />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_ADMIN_REMOVE_SILENCE_CHILD}`,
                                    element: (
                                        <Modal>
                                            <RemoveSilenceSubscription viewerAction="admin" />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: ":subscriptionId/copySubscription",
                                    element: (
                                        <Modal>
                                            <CopySubscriptionToUser />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: ":subscriptionId/moveSubscription",
                                    element: (
                                        <Modal>
                                            <MoveSubscriptionToUser />
                                        </Modal>
                                    ),
                                },
                            ],
                        },
                        {
                            path: NOTIFICATIONS_SUBSCRIPTION_BY_USER_ABS_ROUTE,
                            element: <SubscriptionByUser />,
                            children: [
                                {
                                    path: "inviteUser",
                                    element: (
                                        <Modal>
                                            <InviteUsersModal />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: NOTIFICATIONS_ADMIN_ADD_SUBSCRIPTION_CHILD,
                                    element: (
                                        <Modal>
                                            <AddSubscription
                                                viewerAction={"admin"}
                                            />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_ADMIN_UPDATE_SUBSCRIPTION_CHILD}`,
                                    element: (
                                        <Modal>
                                            <UpdateSubscription
                                                viewerAction={"admin"}
                                            />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_ADMIN_DELETE_SUBSCRIPTION_CHILD}`,
                                    element: (
                                        <Modal>
                                            <DeleteSubscription viewerAction="admin" />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_ADMIN_SILENCE_CHILD}`,
                                    element: (
                                        <Modal>
                                            <ManageSubsriptionSilence viewerAction="admin" />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: `:subscriptionId/${NOTIFICATIONS_ADMIN_REMOVE_SILENCE_CHILD}`,
                                    element: (
                                        <Modal>
                                            <RemoveSilenceSubscription viewerAction="admin" />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: ":subscriptionId/copySubscription",
                                    element: (
                                        <Modal>
                                            <CopySubscriptionToUser />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: ":subscriptionId/moveSubscription",
                                    element: (
                                        <Modal>
                                            <MoveSubscriptionToUser />
                                        </Modal>
                                    ),
                                },
                            ],
                        },
                        {
                            path: NOTIFICATIONS_RULES_ABS_ROUTE,
                            element: <AlertRules />,
                            children: [
                                {
                                    path: "addRule",
                                    element: (
                                        <Modal>
                                            <AddAlertRule />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: ":ruleId/updateRule",
                                    element: (
                                        <Modal>
                                            <UpdateAlertRule />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: ":ruleId/deleteRule",
                                    element: (
                                        <Modal>
                                            <DeleteRule />
                                        </Modal>
                                    ),
                                },
                                {
                                    path: "inviteUser",
                                    element: (
                                        <Modal>
                                            <InviteUsersModal />
                                        </Modal>
                                    ),
                                },
                            ],
                        },
                    ],
                },
                // redirect routes
                {
                    // navigate to "notfications/myNotifications" when "/notifications" is hit
                    path: NOTIFICATIONS_ABS_ROUTE,
                    element: (
                        <Navigate to={MY_NOTIFICATIONS_ABS_ROUTE} replace />
                    ),
                },
                {
                    path: "/n/m",
                    element: (
                        <Navigate
                            to={{
                                pathname: MY_NOTIFICATIONS_ABS_ROUTE,
                                search: location.search,
                            }}
                            replace
                        />
                    ),
                },
                {
                    path: "/admin",
                    element: <Navigate to="/admin/users" replace />,
                },
                {
                    path: "admin/users",
                    element: <UsersTable />,
                    children: [
                        {
                            path: "inviteUser",
                            element: (
                                <Modal>
                                    <InviteUsersModal />
                                </Modal>
                            ),
                        },
                        {
                            path: ":userId/update",
                            element: (
                                <Modal>
                                    <UpdateUserModal />
                                </Modal>
                            ),
                        },
                    ],
                },
                {
                    path: "admin/organizations",
                    element: <OrganizationsTable />,
                    children: [
                        {
                            path: "addOrganization",
                            element: (
                                <Modal>
                                    <AddOrganizationModal />
                                </Modal>
                            ),
                        },
                        {
                            path: ":organizationId/update",
                            element: (
                                <Modal>
                                    <UpdateOrganizationModal />
                                </Modal>
                            ),
                        },
                        {
                            path: "inviteUser",
                            element: (
                                <Modal>
                                    <InviteUsersModal />
                                </Modal>
                            ),
                        },
                    ],
                },
            ],
        },
        {
            path: "*",
            element: <Navigate to="/dashboard" replace />,
        },
    ])

    root.render(
        <React.StrictMode>
            <ApolloProvider client={client}>
                <ConfigContext.Provider value={config}>
                    <AuthProvider {...config.authProviderProps}>
                        <RouterProvider router={trexRouter} />
                    </AuthProvider>
                </ConfigContext.Provider>
            </ApolloProvider>
        </React.StrictMode>
    )
}

Main()
