import { AlertRuleOperator, Unit } from "../../../../../generated/graphql"
import { convertDurationUnit } from "../../../../../util/duration/duration"
import { DurationUnitT } from "../../../../../util/duration/types"
import {
    AlertRuleFormConditionI,
    AlertRuleFormErrorI,
    AlertRuleFormI,
} from "./alertRuleFormTypes"

// Form validation notes:
// If operator is outside range: the following conditions must be met:
// - threshold2 > threshold
// - threshold >= 0 && threshold2 >= 0
// If the operator is outsideRange and the Unit is Duration: the addtional  conditions must be met:
// -  windowTotalSeconds > threshold2

// If opearator is not outside range: the following conditions must be met:
// - threshold >= 0
// If opearator is not outside range and the Unit is Duration: the additional conditions must be met:
// - windowTotalSeconds > threshold

// rule name must not exceed 40 characters. Rule name must not be empty

const getErrorsFromAlertRuleForm = (
    form: AlertRuleFormI | undefined
): AlertRuleFormErrorI => {
    const errors: AlertRuleFormErrorI = {
        threshold: undefined,
        threshold2: undefined,
        ruleName: undefined,
    }

    if (!form) {
        return errors
    }

    const { condition, windowTotalSeconds, ruleName } = form
    const { threshold, threshold2, unit, thresholdTimeUnit } = condition

    // cannot use helper function here because we need to return specific error messages
    // thresholds must be a number greater than or equal to  0
    if (threshold < 0 || typeof threshold !== "number" || isNaN(threshold)) {
        errors.threshold = "Must be greater than or equal to 0"
    }
    if (threshold2 < 0 || typeof threshold2 !== "number" || isNaN(threshold2)) {
        errors.threshold2 = "Must be greater than or equal to 0"
    }

    // If Unit is a duration  threshold  must be < window total seconds
    if (unit === Unit.Duration && thresholdTimeUnit) {
        const validThreshold = isDurationValid(
            threshold,
            thresholdTimeUnit,
            windowTotalSeconds
        )
        if (!validThreshold) {
            errors.threshold = "Must be less than the time range"
        }
    }

    // If operator is outside range - we must check if threshold 2 is valid
    if (condition.operator === AlertRuleOperator.OutsideRange) {
        const validThresholds = isOutsideRangeValid(threshold, threshold2)
        if (!validThresholds) {
            errors.threshold = "Min must be less than max"
        }
        // We also need to check if the threshold2 is less than the window total seconds
        if (unit === Unit.Duration && thresholdTimeUnit) {
            const validThreshold2 = isDurationValid(
                threshold2,
                thresholdTimeUnit,
                windowTotalSeconds
            )
            if (!validThreshold2) {
                errors.threshold2 = "Must be less than the time range"
            }
        }
    }

    // rule name must not exceed 40 characters. Rule name must not be empty
    if (ruleName.length > 40) {
        errors.ruleName = "Rule name must not exceed 40 characters"
    }

    if (ruleName.length === 0) {
        errors.ruleName = "Rule name cannot be empty"
    }

    return errors
}

const isAlertRuleFormDisabled = (form: AlertRuleFormI | undefined): boolean => {
    if (!form) {
        return true
    }

    const { condition, windowTotalSeconds, ruleName } = form
    const validThreshold = isThresholdValid(condition, windowTotalSeconds)
    const validThreshold2 = isThreshold2Valid(condition, windowTotalSeconds)
    const validRuleName = isRuleNameValid(ruleName)

    // if either threshold or threshold2 is invalid return true -- submit button is disabled
    if (!validThreshold || !validThreshold2 || !validRuleName) {
        return true
    }

    // if all conditions are met return false -- submit button is enabled
    return false
}

// EXPORTS
export { isAlertRuleFormDisabled, getErrorsFromAlertRuleForm }

const isRuleNameValid = (ruleName: string): boolean => {
    if (ruleName.length > 40 || ruleName.length === 0) {
        return false
    }
    return true
}

const isThresholdValid = (
    condition: AlertRuleFormConditionI,
    windowTotalSeconds: number
): boolean => {
    const { threshold, unit, operator, thresholdTimeUnit } = condition
    // must be a number greater than or equal to 0
    if (threshold < 0 || typeof threshold !== "number" || isNaN(threshold)) {
        return false
    }
    // If Unit is a duration  - threshold must be < window total seconds
    if (unit === Unit.Duration && thresholdTimeUnit) {
        const { value: thresholdSeconds } = convertDurationUnit(
            {
                unit: thresholdTimeUnit,
                value: threshold,
            },
            "s"
        )
        if (thresholdSeconds >= windowTotalSeconds) {
            return false
        }
    }
    // If operator is outside range - threshold2 must be greater than threshold
    if (operator === AlertRuleOperator.OutsideRange) {
        const { threshold2 } = condition

        const validThresholds = isOutsideRangeValid(threshold, threshold2)
        if (!validThresholds) {
            return false
        }
    }

    // if all conditions are met threshold is valid, return true
    return true
}

const isThreshold2Valid = (
    condition: AlertRuleFormConditionI,
    windowTotalSeconds: number
) => {
    const { unit, operator, thresholdTimeUnit, threshold2 } = condition

    // must be >= 0
    if (threshold2 < 0 || typeof threshold2 !== "number" || isNaN(threshold2)) {
        return false
    }

    // If Unit is a duration  - threshold2 must be <  window total seconds
    if (unit === Unit.Duration && thresholdTimeUnit) {
        const validThreshold2 = isDurationValid(
            threshold2,
            thresholdTimeUnit,
            windowTotalSeconds
        )
        if (!validThreshold2) {
            return false
        }
    }

    // If operator is outside range - threshold2 must be greater than threshold
    if (operator === AlertRuleOperator.OutsideRange) {
        const { threshold } = condition

        const validThresholds = isOutsideRangeValid(threshold, threshold2)
        if (!validThresholds) {
            return false
        }
    }

    // if all conditions are met threshold2 is valid, return true
    return true
}

// If Unit is a duration  - threshold must be less than window total seconds
const isDurationValid = (
    threshold: number,
    thresholdTimeUnit: DurationUnitT,
    windowTotalSeconds: number
): boolean => {
    const { value: thresholdSeconds } = convertDurationUnit(
        {
            unit: thresholdTimeUnit,
            value: threshold,
        },
        "s"
    )
    if (thresholdSeconds >= windowTotalSeconds) {
        return false
    }
    return true
}

const isOutsideRangeValid = (
    threshold: number,
    threshold2: number
): boolean => {
    if (threshold2 <= threshold) {
        return false
    }
    return true
}
