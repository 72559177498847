import { ReactNode } from "react"
import { Link } from "react-router-dom"
import { TableColumnInterface } from "../../../types/tableTypes"
import { UserDataInterface } from "./userTableTypes"
import { GetUsersQuery } from "../../../generated/graphql"
import { IDENTITY_FIELDS_FRAGMENT } from "../../../graphql/fragments/Identity"
import { getFragmentData } from "../../../generated"
import { ORGANIZATION_WITH_DESCENDANTS_FIELDS_FRAGMENT } from "../../../graphql/fragments/organizations"
import { RolesUiDisplayObj } from "../../../util/subjectRole"
import styles from "./user.module.scss"

export const formatUserData = (rawData: GetUsersQuery) => {
    const data: UserDataInterface[] = []
    const { edges } = rawData.users

    if (edges) {
        for (let i = 0; i < edges.length; i++) {
            const node = edges[i].node
            if (!node) continue
            if (!node.identity) continue

            const identityFragment = getFragmentData(
                IDENTITY_FIELDS_FRAGMENT,
                node.identity
            )
            const organizationFragment = getFragmentData(
                ORGANIZATION_WITH_DESCENDANTS_FIELDS_FRAGMENT,
                node.organization
            )

            const user: UserDataInterface = {
                id: node.id,
                role: node.role,
                wells: "",
                organization: {
                    id: organizationFragment.id,
                    name: organizationFragment.name,
                },
                identity: {
                    email: identityFragment?.email,
                    emailVerified: identityFragment?.emailVerified,
                    firstName:
                        identityFragment?.firstName.length > 0
                            ? identityFragment?.firstName
                            : "[no_first_name]",
                    lastName:
                        identityFragment?.lastName.length > 0
                            ? identityFragment?.lastName
                            : "[no_last_name]",
                    active: identityFragment?.active,
                    fullName: identityFragment?.fullName,
                    phoneNumber: identityFragment?.phoneNumber,
                    phoneNumberVerified: identityFragment?.phoneNumberVerified,
                    zoneInfo: identityFragment?.zoneInfo,
                    smsConsent: identityFragment?.smsConsent,
                    emailConsent: identityFragment?.emailConsent,
                },
            }
            data.push(user)
        }
    }
    return data
}

const getUserColumn = (obj: UserDataInterface): ReactNode => {
    // if either first or last name is not empty, return concat of both, otherwise use placeholder
    const { firstName, lastName } = obj.identity
    const hasName = firstName || lastName
    const isValidName = hasName && /\S/.test(firstName + lastName)

    const displayName = isValidName ? (
        <span>{`${firstName} ${lastName}`}</span>
    ) : (
        <span>[no_name_found]</span>
    )

    return (
        <Link
            className={styles.tableLink}
            to={`/admin/users/${obj.id}/update`}
            state={{
                firstName: obj.identity.firstName,
                lastName: obj.identity.lastName,
                email: obj.identity.email,
                userRole: obj.role,
                activeUser: obj.identity.active,
                organizationName: obj.organization.name,
            }}
        >
            {displayName}
        </Link>
    )
}

const UserTableConfigData: TableColumnInterface<UserDataInterface>[] = [
    {
        label: "User",
        id: "user",
        render: (obj: UserDataInterface) => <>{getUserColumn(obj)}</>,
        headerCustomClass: "headerUserUser",
        cellCustomClass: "cellUserUser",
    },
    {
        label: "Email",
        id: "email",
        render: (obj: UserDataInterface) => obj.identity.email,
        headerCustomClass: "headerUserEmail",
        cellCustomClass: "cellUserEmail",
    },
    {
        label: "Role",
        id: "role",
        render: (obj) => RolesUiDisplayObj[obj.role],
        headerCustomClass: "headerUserRole",
        cellCustomClass: "cellUserRole",
    },
    {
        label: "User Status",
        id: "userStatus",
        render: (obj) => (
            <div> {obj.identity.active ? "Active" : "Inactive"}</div>
        ),
        headerCustomClass: "headerUserUserStatus",
        cellCustomClass: "cellUserUserStatus",
    },
]

export { UserTableConfigData }
