import React, {
    createContext,
    useContext,
    FunctionComponent,
    ReactNode,
    useState,
} from "react"
import { OrganizationOptionType } from "../../../types/ViewerTypes"
import { useViewerContext } from "../../../context/ViewerContext"

// Define the context type
interface NotificationsContextType {
    selectedOrganization: OrganizationOptionType
    updateSelectedOrganization: (organization: OrganizationOptionType) => void
}

const NotificationsContext = createContext<
    NotificationsContextType | undefined
>(undefined)

// Create Provider
const NotificationsContextProvider: FunctionComponent<{
    children: ReactNode
}> = ({ children }) => {
    const viewer = useViewerContext().getViewer()

    // state
    const [selectedOrganization, setSelectedOrganization] =
        useState<OrganizationOptionType>(viewer.currentOrganization)

    // functions
    const updateSelectedOrganization = (
        organization: OrganizationOptionType
    ) => {
        setSelectedOrganization(organization)
    }

    return (
        <NotificationsContext.Provider
            value={{
                selectedOrganization,
                updateSelectedOrganization,
            }}
        >
            {children}
        </NotificationsContext.Provider>
    )
}

// Custom hook to use the NotificationsContext
const useNotificationsContext = () => {
    const context = useContext(NotificationsContext)
    if (!context) {
        throw new Error(
            "useNotificationsContext must be used within a NotificationsContextProvider"
        )
    }
    return context
}

export { NotificationsContextProvider, useNotificationsContext }
