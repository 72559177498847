import { LabelValueOptionType } from "../../../../../types/commonTypes"
import {
    durationUnitValueToDisplay,
    secondsToLargestWholeDurationUnit,
} from "../../../../../util/duration/duration"

// Note that  addAlertRule && updateAlertRule UI forms -  selecting a new window selects a new alert rule template.
// handleWindowChange in addAlertRule and updateAlertRule formContainer.tsx use the value of the window select to find the alertRuleTemplate. Before changing this function consider how these handlers will be affected.
const createAlertRuleWindowInputOption = (
    totalSeconds: number
): LabelValueOptionType => {
    const { value, unit } = secondsToLargestWholeDurationUnit(totalSeconds, "d")
    const displayResult = durationUnitValueToDisplay({ value, unit })

    return {
        label: `${displayResult.value} ${displayResult.unitLabel}`,
        value: totalSeconds.toString(),
    }
}

// tool tip constants
export const METRIC_TT_TEXT =
    "Choose what will be measured to trigger an alert."
export const TIME_RANGE_TT_TEXT =
    "The length of time that the metric will be measured across."
export const DESCRIPTION_TT_TEXT =
    "Additional context in your own words to understand what this alert rule is for."
export const THRESHOLD_GT_TT_TEXT =
    "The measurement at which this metric must be above to trigger an alert."
export const THRESHOLD_LT_TT_TEXT =
    "The measurement at which this metric must be below to trigger an alert."
export const THRESHOLD_OR_TT_TEXT =
    "The min and max measurements that the metric must be outside to trigger an alert."
export const TRHESHOLD_VARIANCE_TT_TEXT =
    "The measurement at which this metric's variance must exceed to trigger an alert."

export { createAlertRuleWindowInputOption }
