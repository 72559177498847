import { FunctionComponent } from "react"
import ModalHeader from "../../../../shared/modalHeader"
import ModalFooter from "../../../../shared/modalFooter"
import { TrexNavigator } from "../../../../../classes/navigator/navigator"
import { useLocation, useNavigate } from "react-router-dom"

const CopySubscriptionToUser: FunctionComponent = () => {
    const navigate = useNavigate()
    const { key: locationKey } = useLocation()

    return (
        <div>
            <ModalHeader
                title="Copy Subscription to user"
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: locationKey,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
            />
            <div>Copy Subscription to user</div>
            <ModalFooter
                advanceText="Save"
                disableAdvance={false}
                handleAdvanceClick={() => {
                    console.log("advance clicked")
                }}
            />
        </div>
    )
}

export default CopySubscriptionToUser
