import {
    FunctionComponent,
    useDeferredValue,
    useEffect,
    useMemo,
    useState,
} from "react"
import { MyNotificationsTableDataI } from "./tableTypes"
import { useSearchParams } from "react-router-dom"
import {
    ColumnFiltersState,
    SortingState,
    Table,
    flexRender,
    getCoreRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { tableStateToUrlSearchParams } from "../../../../table/tableUtil"
import styles from "./table.module.scss"
import classNames from "classnames"
import { myNotificationsColumns } from "./column/column"
import {
    getMyNotificationsStateFromUrlParams,
    myNotificationsPageSizeSelectorArray,
    myNotificationsTableUrlMapping,
} from "./tableUtil"
import MyNotificationsActionHeader from "./actionHeader"
import MyNotificationsTableHeader from "./header"
import TanStackPaginator from "../../../../shared/tanStack/paginator"

interface MyNotificationsTableComponentI {
    data: MyNotificationsTableDataI[]
}

const MyNotificationsTable: FunctionComponent<
    MyNotificationsTableComponentI
> = ({ data }) => {
    // init state from url
    const [searchParams, setSearchParams] = useSearchParams()

    const initUrlState = useMemo(() => {
        return getMyNotificationsStateFromUrlParams(
            searchParams,
            myNotificationsTableUrlMapping
        )
    }, [data])

    // table state
    const [globalFilter, setGlobalFilter] = useState<string>(
        initUrlState.search
    )
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
        initUrlState.filter
    )

    const [sortingState, setSortingState] = useState<SortingState>(
        initUrlState.sorting
    )
    const [paginationState, setPaginationState] = useState({
        pageIndex: initUrlState.pageIndex,
        pageSize: initUrlState.size,
    })

    // deferred state
    const deferredGlobalFilter = useDeferredValue(globalFilter)
    const deferredColumnFilters = useDeferredValue(columnFilters)
    const isStateDeferred =
        deferredGlobalFilter !== globalFilter ||
        deferredColumnFilters !== columnFilters

    // table instance
    const table = useReactTable({
        columns: myNotificationsColumns,
        data: data,
        // autoResetPageIndex is because false : if true, the page index will be reset to 0 whenever the data changes (from a mutation).
        // That creates a bad user experience, in this circumstance if the user updates a subscription on page 5 they would be directed back to page 1.
        // When autoResetPageIndex is false, we need to manually reset the page index when updating filters, globals and sorting
        autoResetPageIndex: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getPaginationRowModel: getPaginationRowModel(),
        onGlobalFilterChange: (updater) => {
            table.setPageIndex(0)
            return setGlobalFilter(updater)
        },
        onColumnFiltersChange: (updater) => {
            table.setPageIndex(0)
            return setColumnFilters(updater)
        },
        onPaginationChange: (updater) => {
            return setPaginationState(updater)
        },
        onSortingChange: (updater) => {
            table.setPageIndex(0)
            return setSortingState(updater)
        },
        state: {
            globalFilter: deferredGlobalFilter,
            columnFilters: deferredColumnFilters,
            pagination: paginationState,
            sorting: sortingState,
        },
    })

    // Effect hook to keep app state in sync with URL
    // if the dependencies change based on updated table state - this effect will fire and update the URL
    useEffect(() => {
        const newParams = tableStateToUrlSearchParams(
            paginationState,
            sortingState,
            columnFilters,
            globalFilter
        )
        setSearchParams(newParams, { replace: true })
        // having data as a dependency allows the resynchronization of app state & url state after mutations
    }, [columnFilters, sortingState, globalFilter, paginationState, data])

    return (
        <>
            <div className={styles.tableContainer}>
                <MyNotificationsActionHeader
                    globalFilter={globalFilter}
                    table={table}
                />
                <div className={styles.overflowContainer}>
                    <table className={styles.tableClass}>
                        <MyNotificationsTableHeader table={table} />
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr
                                    className={classNames(
                                        styles.tableRow,
                                        isStateDeferred && styles.deferredRow
                                    )}
                                    key={row.id}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <td
                                            className={classNames(
                                                styles.tableCell,
                                                styles[`${cell.column.id}_Cell`]
                                            )}
                                            key={cell.id}
                                        >
                                            <span className={styles.cellSpan}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </span>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <TanStackPaginator
                    pageSizeOptions={myNotificationsPageSizeSelectorArray}
                    tableInstance={table as Table<unknown>}
                />
            </div>
        </>
    )
}

export default MyNotificationsTable
