import { Table } from "@tanstack/react-table"
import { FunctionComponent, useEffect, useState } from "react"
import {
    faChevronDoubleDown,
    faChevronDoubleUp,
} from "@fortawesome/pro-solid-svg-icons"
import styles from "./actionHeader.module.scss"
import Button from "../../../../../shared/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Input from "../../../../../shared/input"
import { sortArray } from "../../../../../../util/arrays/array"
import {
    SubscriptionsAdminTableDataI,
    SubscriptionsAdminWellNameSep,
} from "../../tableTypes"
import {
    METRIC_COLUMN_ID,
    SUBSCRIBED_WELLS_COLUMN_ID,
    SUBSCRIBER_COLUMN_ID,
} from "../../column"
import { debounce } from "../../../../../../util/debounce"
import { useNavigate } from "react-router-dom"
import { NOTIFICATIONS_ADMIN_ADD_SUBSCRIPTION_CHILD } from "../../../../../.."

interface SubscriptionByUserActionHeaderI {
    table: Table<SubscriptionsAdminTableDataI>
    globalFilter: string
}

const SubscriptionsByUserActionHeader: FunctionComponent<
    SubscriptionByUserActionHeaderI
> = ({ table, globalFilter }) => {
    const navigate = useNavigate()

    // Note because these action headers is more busy we need to increase the mobile breakpoint, this is slightly different than the typical flow of useing useBreakPointContext
    // if the @mobileBreakPoint is updated the media queries in the corresponding scss files need to be updated to match the value - actionHeader.module.scss,table.module.scss, subscriptionByRule.module.scss
    const mobileBreakPoint = 1750
    const [isMobile, setIsMobile] = useState(
        window.innerWidth < mobileBreakPoint
    )

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < mobileBreakPoint)
        }
        const debouncedHandleResize = debounce(handleResize, 200)
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    }, [])

    // state
    const [showActions, setShowActions] = useState(true)

    // font awesome icon
    const fontAwesomeIcon = showActions
        ? faChevronDoubleUp
        : faChevronDoubleDown

    // styling classes
    // in mobile
    const outerClass =
        isMobile && !showActions ? styles.outerClosed : styles.outer

    // get columns
    const metricColumn = table.getColumn(METRIC_COLUMN_ID)
    const subscribedWellsColumn = table.getColumn(SUBSCRIBED_WELLS_COLUMN_ID)
    const subscriberColumn = table.getColumn(SUBSCRIBER_COLUMN_ID)

    // unique metrics
    const m = metricColumn?.getFacetedUniqueValues().keys() ?? []
    const uniqueMetrics = Array.from(m).sort()

    // unique wells
    const wellKeys =
        subscribedWellsColumn?.getFacetedUniqueValues().keys() ?? []
    const arrayOfWells = Array.from(wellKeys)
    const uniqueWells = Array.from(
        new Set(
            arrayOfWells.flatMap((well) => {
                return well.split(SubscriptionsAdminWellNameSep)
            })
        )
    )

    const sortedUniqueWells = sortArray(uniqueWells) as string[]

    // unique subscribers
    const r = subscriberColumn?.getFacetedUniqueValues().keys() ?? []
    const uniqueSubscribers = Array.from(r).sort()

    const subscriberOptions = uniqueSubscribers.map((subscriber) => {
        return {
            label: subscriber as string,
            value: subscriber as string,
        }
    })

    const metricFilterValue = (metricColumn?.getFilterValue() as string) || ""
    const wellFilterValue =
        (subscribedWellsColumn?.getFilterValue() as string) || ""

    const subscriberFilterValue =
        (subscriberColumn?.getFilterValue() as string) || ""

    // we need to set the filter value to the first subscriber option, if it is not set
    useEffect(() => {
        if (!subscriberFilterValue) {
            subscriberColumn?.setFilterValue(subscriberOptions[0].value)
        }
    }, [table])

    // useEffect: if we resize from mobile => desktop sizing we need to setShowActions to true
    useEffect(() => {
        if (!isMobile) {
            setShowActions(true)
        }
    }, [isMobile])

    return (
        <div className={outerClass}>
            {isMobile && (
                <Button
                    handleButtonClick={() => setShowActions(!showActions)}
                    status="primary"
                    customButtonClassName={styles.mobileButton}
                >
                    <FontAwesomeIcon icon={fontAwesomeIcon} color="#fff" />
                </Button>
            )}
            {showActions && (
                <div className={styles.container}>
                    <div className={styles.containerLeft}>
                        <div className={styles.subscriberFilter}>
                            <Input
                                onChange={(e) => {
                                    subscriberColumn?.setFilterValue(
                                        e.target.value
                                    )
                                }}
                                orientation={
                                    isMobile ? "vertical" : "horizontal"
                                }
                                type="select"
                                options={subscriberOptions}
                                specializedClass="noMessage"
                                value={subscriberFilterValue}
                                label="Subscriber:"
                                customLabelClass={styles.subscriberLabel}
                            />
                        </div>
                        <div className={styles.addSubscriptionBtn}>
                            <Button
                                handleButtonClick={() => {
                                    navigate(
                                        NOTIFICATIONS_ADMIN_ADD_SUBSCRIPTION_CHILD
                                    )
                                }}
                                status="primary"
                                condition="default"
                            >
                                Add Subscription
                            </Button>
                        </div>
                        <div className={styles.addSubscriptionBtn}>
                            <Button
                                handleButtonClick={() => {
                                    // todo - set up once add subscription is set up
                                    console.log("unimplemented")
                                    // navigate("addRule")
                                }}
                                status="primary"
                                condition="default"
                                disabled={true}
                            >
                                Copy To Selected User
                            </Button>
                        </div>
                        <div className={styles.addSubscriptionBtn}>
                            <Button
                                handleButtonClick={() => {
                                    // todo - set up once add subscription is set up
                                    console.log("unimplemented")
                                    // navigate("addRule")
                                }}
                                status="primary"
                                condition="default"
                                disabled={true}
                            >
                                Move to Selected User
                            </Button>
                        </div>
                        <div className={styles.metricFilter}>
                            <Input
                                onChange={(e) => {
                                    metricColumn?.setFilterValue(e.target.value)
                                }}
                                orientation="horizontal"
                                type="datalist"
                                placeHolder="Filter By Metric"
                                datalistId="alertRuleMetric"
                                options={uniqueMetrics}
                                specializedClass="noMessage"
                                value={metricFilterValue}
                            />
                        </div>
                        <div className={styles.metricFilter}>
                            <Input
                                onChange={(e) => {
                                    subscribedWellsColumn?.setFilterValue(
                                        e.target.value
                                    )
                                }}
                                orientation="horizontal"
                                type="datalist"
                                placeHolder="Filter By Well"
                                datalistId="wellDatalist"
                                options={sortedUniqueWells}
                                specializedClass="noMessage"
                                value={wellFilterValue}
                            />
                        </div>
                    </div>
                    <div className={styles.containerRight}>
                        <div className={styles.globalFilter}>
                            <Input
                                value={globalFilter}
                                onChange={(e) => {
                                    table.setGlobalFilter(e.target.value)
                                }}
                                type="text"
                                orientation="horizontal"
                                placeHolder="Search Columns"
                                specializedClass="noMessage"
                                inputCustomClass={styles.searchFilter}
                            />
                        </div>
                        <Button
                            handleButtonClick={() => {
                                table.setGlobalFilter("")
                            }}
                            status="secondary"
                            condition="default"
                        >
                            Clear
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SubscriptionsByUserActionHeader
