import { getFragmentData } from "../../../../generated"
import { SubscriptionFieldsFragment } from "../../../../generated/graphql"
import { IDENTITY_FIELDS_FRAGMENT } from "../../../../graphql/fragments/Identity"
import { ALERT_RULE_FIELDS_FRAGMENT } from "../../../../graphql/fragments/alertRule"
import { ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT } from "../../../../graphql/fragments/alertRuleMetricDefinition"
import { DATE_TIME_FIELDS_FRAGMENT } from "../../../../graphql/fragments/dateTime"
import { SUBJECT_FIELDS_FRAGMENT } from "../../../../graphql/fragments/subject"
import { WELL_IDENTIFICATION_FIELDS_FRAGMENT } from "../../../../graphql/fragments/wellIdentification"
import { LabelValueOptionType } from "../../../../types/commonTypes"
import { SubscriptionsAdminTableDataI } from "../adminSubscriptionsViews/tableTypes"
import { UpdateSubscriptionFormI } from "../manageSubscriptions/manageSubscriptionFormUtil/types"
import {
    MySubscriptionsTableDataI,
    mySubscriptionsWellNameSep,
} from "../mySubscriptions/table/tableTypes"

const getMySubscriptionsTableFromSubscriptionsFrag = (
    subscriptions: SubscriptionFieldsFragment[]
): MySubscriptionsTableDataI[] => {
    const mySubscriptionsTable: MySubscriptionsTableDataI[] = []
    for (let i = 0; i < subscriptions.length; i++) {
        const subscription = subscriptions[i]

        const alertRule = getFragmentData(
            ALERT_RULE_FIELDS_FRAGMENT,
            subscription.alertRule
        )

        const metric = getFragmentData(
            ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT,
            alertRule.metric
        )

        const subscribedWells = getFragmentData(
            WELL_IDENTIFICATION_FIELDS_FRAGMENT,
            subscription.subscribedWells
        )

        // ex: wellNames = "well1|well2|well3"
        const wellNames = subscribedWells
            .map((well) => well.name)
            .join(mySubscriptionsWellNameSep)

        mySubscriptionsTable.push({
            id: subscription.id,
            ruleName: alertRule.name,
            metric: metric.displayName,
            ruleDescription: alertRule.description,
            subscribedWells: wellNames,
        })
    }

    return mySubscriptionsTable
}

const getSubscriptionsAdminTableFromSubscriptionsFrag = (
    subscriptions: SubscriptionFieldsFragment[]
): SubscriptionsAdminTableDataI[] => {
    const subscriptionsByRuleTable: SubscriptionsAdminTableDataI[] = []

    for (let i = 0; i < subscriptions.length; i++) {
        const subscription = subscriptions[i]

        const alertRule = getFragmentData(
            ALERT_RULE_FIELDS_FRAGMENT,
            subscription.alertRule
        )

        const metric = getFragmentData(
            ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT,
            alertRule.metric
        )

        const subscribedWells = getFragmentData(
            WELL_IDENTIFICATION_FIELDS_FRAGMENT,
            subscription.subscribedWells
        )

        const subscriber = getFragmentData(
            SUBJECT_FIELDS_FRAGMENT,
            subscription.subscriber
        )
        const subscriberIdentity = getFragmentData(
            IDENTITY_FIELDS_FRAGMENT,
            subscriber.identity
        )

        // ex: wellNames = "well1|well2|well3"
        const wellNames = subscribedWells
            .map((well) => well.name)
            .join(mySubscriptionsWellNameSep)

        subscriptionsByRuleTable.push({
            email: subscription.email,
            id: subscription.id,
            metric: metric.displayName,
            phone: subscription.phone,
            subscriber: subscriberIdentity?.fullName || "",
            subscribedWells: wellNames,
            ruleName: alertRule.name,
        })
    }

    return subscriptionsByRuleTable
}

const getUpdateSubscriptionFormFromSubscriptionFrag = (
    subscription: SubscriptionFieldsFragment,
    availableWells: LabelValueOptionType[]
): UpdateSubscriptionFormI => {
    const alertRule = getFragmentData(
        ALERT_RULE_FIELDS_FRAGMENT,
        subscription.alertRule
    )

    const subscribedWells = getFragmentData(
        WELL_IDENTIFICATION_FIELDS_FRAGMENT,
        subscription.subscribedWells
    )

    const initialSilenceUnixMs = getFragmentData(
        DATE_TIME_FIELDS_FRAGMENT,
        subscription.silenceUntil
    ).unixMilliseconds

    const wellOptions = availableWells.map((well) => {
        const isSubscribed = subscribedWells.findIndex((subscribedWell) => {
            return subscribedWell.id === well.value
        })

        if (isSubscribed !== -1) {
            return {
                label: well.label,
                value: well.value,
                isDisabled: false,
                isSelected: true,
            }
        } else {
            return {
                label: well.label,
                value: well.value,
                isDisabled: false,
                isSelected: false,
            }
        }
    })

    const subscriber = getFragmentData(
        SUBJECT_FIELDS_FRAGMENT,
        subscription.subscriber
    )

    const identity = getFragmentData(
        IDENTITY_FIELDS_FRAGMENT,
        subscriber.identity
    )

    const form: UpdateSubscriptionFormI = {
        rule: {
            label: alertRule.name,
            value: alertRule.id,
        },
        wellOptions: wellOptions,
        // includeNewWells: subscription.includeNewWells,
        enableEmail: subscription.email,
        enableText: subscription.phone,
        byPassDnd: subscription.disturb,
        subscriptionId: subscription.id,
        silenceForm: {
            timeUnit: "m",
            timeValue: "",
        },
        initialSilenceUnixMs: initialSilenceUnixMs,
        subscriber: {
            label: identity?.fullName || "",
            id: subscriber.id,
            email: identity?.email,
            phone: identity?.phoneNumber,
            emailVerified: identity?.emailVerified || false,
            emailConsent: identity?.emailConsent || false,
            phoneVerified: identity?.phoneNumberVerified || false,
            phoneConsent: identity?.smsConsent || false,
        },
    }

    return form
}
export {
    getMySubscriptionsTableFromSubscriptionsFrag,
    getSubscriptionsAdminTableFromSubscriptionsFrag,
    getUpdateSubscriptionFormFromSubscriptionFrag,
}
