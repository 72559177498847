import { getFragmentData } from "../../../../../generated"
import { AlertRuleFieldsFragment } from "../../../../../generated/graphql"
import { ALERT_RULE_CONDITION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRuleCondition"
import { ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRuleMetricDefinition"
import { DURATION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/duration"
import { AlertRuleFormI } from "../alertRuleFormUtil/alertRuleFormTypes"
import { getInitialAlertRuleFormCondition } from "./alertRuleConditionFragment"

const getAlertRuleFormFromAlertRuleFragment = (
    rule: AlertRuleFieldsFragment
): AlertRuleFormI => {
    const conditionFragment = getFragmentData(
        ALERT_RULE_CONDITION_FIELDS_FRAGMENT,
        rule.condition
    )
    const windowFragment = getFragmentData(
        DURATION_FIELDS_FRAGMENT,
        rule.window
    )

    const alertRuleMetricDefinitionFragment = getFragmentData(
        ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT,
        rule.metric
    )

    const formCondition = getInitialAlertRuleFormCondition(conditionFragment)

    return {
        description: rule.description,
        metric: alertRuleMetricDefinitionFragment.metric,
        ruleName: rule.name,
        condition: formCondition,
        windowTotalSeconds: windowFragment.totalSeconds,
        evaluationGroup: rule.evaluationGroup,
    }
}

export { getAlertRuleFormFromAlertRuleFragment }
