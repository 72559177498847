import { TableStateType } from "../../../../table/tableTypes"
import { METRIC_COLUMN_ID, SUBSCRIBED_WELLS_COLUMN_ID } from "./column"

export type MySubscriptionsTableDataI = {
    id: string
    ruleName: string
    metric: string
    ruleDescription: string
    // The query result that populates subscribedWells, will be an array of wells. Map each name into a string separated by @mySubscriptionsWellNameSep (a pipe "|"). Pipe is used as a separator because it is not likely used in well names.
    // This will allow the global filter to search for a well name in the subscribedWells column. See mySubscriptionsWellNameSep below and find all references
    subscribedWells: string
}

export const mySubscriptionsWellNameSep = "|"

type MySubscriptionsCustomUrlParamsT = {
    [SUBSCRIBED_WELLS_COLUMN_ID]: ""
    [METRIC_COLUMN_ID]: ""
}

export type MySubscriptionsUrlToStateMappings = {
    [k in keyof MySubscriptionsCustomUrlParamsT]: (
        value: string,
        state: TableStateType
    ) => void
}
