import { FunctionComponent, ReactNode } from "react"
import AlertRulesTable from "./table/table"
import styles from "./rules.module.scss"
import { Outlet, useNavigate } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { GET_ALERT_RULES } from "../../../graphql/queries/rules"
import {
    ErrorMessage,
    SyncLoaderComponent,
    EmptyState,
} from "../../shared/graphQlResponse"
import { formatAlertRulesData } from "./table/tableUtil"
import { useViewerContext } from "../../../context/ViewerContext"
import { canRoleAddAlertRule } from "../../../util/rolePermissions/canRole"
import Button from "../../shared/button"
import { useNotificationsContext } from "../context/notificationsContext"

const AlertRules: FunctionComponent = () => {
    const notificationsContext = useNotificationsContext()
    const { error, loading, data } = useQuery(GET_ALERT_RULES, {
        fetchPolicy: "cache-and-network",
        variables: {
            RulesFilter: {
                OrganizationIDs: [
                    notificationsContext.selectedOrganization.value,
                ],
            },
        },
    })

    let body: ReactNode
    if (error) {
        body = (
            <ErrorMessage
                outSideDivStyle={styles.error}
                message="There was a problem fetching your alert rules."
            />
        )
    }
    if (loading) {
        body = <SyncLoaderComponent outSideDivStyle={styles.loader} />
    }
    if (data) {
        const formattedData = formatAlertRulesData(data)
        if (data.rules.rules && data.rules.rules.length === 0) {
            body = <AlertRuleEmptyState />
        } else {
            body = <AlertRulesTable data={formattedData} />
        }
    }

    return (
        <>
            <Outlet />
            <div className={styles.container}>{body}</div>
        </>
    )
}

export default AlertRules

const AlertRuleEmptyState: FunctionComponent = () => {
    const navigate = useNavigate()
    const viewer = useViewerContext().getViewer()
    const { role } = viewer
    const action = canRoleAddAlertRule(role) ? (
        <Button
            handleButtonClick={() => {
                navigate("addRule")
            }}
            status="primary"
            condition="default"
            customButtonClassName={styles.addRuleButton}
        >
            Add alert rule
        </Button>
    ) : (
        <div className={styles.emptySubtitle}>
            Reach out to your organization administrator to add alert rules.
        </div>
    )
    return (
        <EmptyState outSideDivStyle={styles.emptyStateContainer}>
            <div className={styles.emptyTitle}> No alert rules found.</div>
            {action}
        </EmptyState>
    )
}
