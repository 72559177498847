/**
 * Returns the initials of a person's first and last name.
 *
 * @param firstName - The first name of the person.
 * @param lastName - The last name of the person.
 * @returns The initials of the person's first and last name in the format "AB".
 *          If only one name is provided, returns the initial of that name.
 *          If both names are missing, returns an empty string.
 */
const getInitials = (firstName?: string, lastName?: string): string => {
    const getInitial = (name: string | undefined): string => {
        return name ? name.charAt(0).toUpperCase() : ""
    }
    const firstNameInitial = getInitial(firstName)
    const last_lastName = lastName ? lastName.split(" ").pop() : undefined
    const lastNameInitial = getInitial(last_lastName)
    return `${firstNameInitial}${lastNameInitial}`
}

/**
 *
 * isValidEmail checks for:
 * - One or more non-whitespace characters before the "@" symbol.
 * - The presence of a "@" symbol.
 * - One or more non-whitespace characters after the "@" symbol and before the dot.
 * - A dot followed by one or more non-whitespace characters.
 * - No whitespace characters are allowed in the entire email address.
 *
 * @param email - The email address to be validated.
 * @returns A boolean indicating whether the email address is valid.
 *
 * consider if this function suits your needs before using.
 * https://www.regular-expressions.info/email.html
 * https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
 */

const isValidEmail = (email: string): boolean => {
    const emailPattern = /^\S+@\S+\.\S+$/
    return emailPattern.test(email)
}

const isValidPhoneNumber = (phoneNumber: string): boolean => {
    console.log(` isValidPhoneNumber unimplemented (${phoneNumber})`)
    return true
}

export { getInitials, isValidEmail, isValidPhoneNumber }
