import React, { useState, FunctionComponent } from "react"
import { useNavigate } from "react-router-dom"
import styles from "./form.module.scss"
import { USER_SETTINGS_ABS_ROUTE } from "../../../.."
import Input from "../../../shared/input"
import MutationErrorBanner from "../../../shared/graphQlResponse"
import ModalFooter from "../../../shared/modalFooter"
import { getErrorsFromNotificationsPhoneNumberForm } from "../updatePhoneNumberFormUtil/validation"
import {
    UpdateProfilePhoneNumberFormErrorI,
    UpdateProfilePhoneNumberFormI,
} from "../updatePhoneNumberFormUtil/types"
import { UPDATE_USERS } from "../../../../graphql/mutations/update_users"
import { useMutation } from "@apollo/client"

const formatPhoneNumber = (value: string) => {
    const cleanedValue = value.replace(/\D/g, "") // Remove non-digit characters
    const formattedValue = cleanedValue.slice(0, 10) // Keep only the first 10 digits

    return formattedValue
}

const removeCountryCode = (value: string) => {
    return value.slice(2)
}

const UpdatePhoneNumberFormComponent: FunctionComponent<{
    userId: string
    phoneNumber: string
}> = ({ userId, phoneNumber }) => {
    const navigate = useNavigate()
    // mutation
    const [updateProfileMutation, { loading: updateProfileMutationLoading }] =
        useMutation(UPDATE_USERS)

    // form state
    const [form, setForm] = useState<UpdateProfilePhoneNumberFormI>({
        phoneNumber: removeCountryCode(phoneNumber) || "",
        dirty: false,
    } as UpdateProfilePhoneNumberFormI)

    const [formErrors, setFormErrors] =
        useState<UpdateProfilePhoneNumberFormErrorI>({ phoneNumber: undefined })

    const [mutationSuccess, setMutationSuccess] = useState(false)
    const [mutationErrorBanner, setMutationErrorBanner] = useState(false)

    // form handlers
    const handleChangePhoneNumber = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const targetValue = e.target.value
        const formattedValue = formatPhoneNumber(targetValue)
        const updatedForm = {
            ...form,
            phoneNumber: formattedValue,
            dirty: true,
        }

        // if there is a form error and user corrects it, we can clear it on valid input instead of waiting for blur.
        // if there is no current error we should wait for blur.
        let updatedErrors = { ...formErrors }
        if (formErrors.phoneNumber) {
            updatedErrors =
                getErrorsFromNotificationsPhoneNumberForm(updatedForm)
        }

        setForm(updatedForm)
        setFormErrors(updatedErrors)
    }

    // blur handlers
    const handlePhoneNumberBlur = () => {
        const updatedErrors = getErrorsFromNotificationsPhoneNumberForm(form)
        setFormErrors(updatedErrors)
    }

    // submission handler
    const handleSubmit = async () => {
        try {
            await updateProfileMutation({
                variables: {
                    UserUpdateInput: {
                        userID: userId,
                        phoneNumber: `+1${form.phoneNumber}`,
                    },
                },
            })
            setMutationSuccess(true)
            navigate(USER_SETTINGS_ABS_ROUTE)
        } catch (err) {
            console.error("update phone number modal mutation error", err)
            setMutationErrorBanner(true)
        }
    }

    // show submission component on mutation success
    if (mutationSuccess) {
        return <UpdatePhoneNumberSuccessComponent />
    }
    // if no mutation success, show form
    return (
        <>
            <div className={styles.container}>
                <div>
                    <Input
                        type="text"
                        label="Phone Number"
                        orientation="horizontal"
                        value={form?.phoneNumber}
                        onChange={handleChangePhoneNumber}
                        onBlur={handlePhoneNumberBlur}
                        message={
                            formErrors.phoneNumber
                                ? formErrors.phoneNumber
                                : undefined
                        }
                        specializedClass={
                            formErrors.phoneNumber ? "danger" : "default"
                        }
                        customLabelClass={styles.phoneNumberInputLabel}
                        phoneNumber
                    />
                </div>
            </div>
            {mutationErrorBanner && (
                <>
                    <MutationErrorBanner
                        message={
                            <div>
                                There was a problem updating your phone number
                                preferences. Click{" "}
                                <u
                                    style={{
                                        cursor: "pointer",
                                        marginRight: "4px",
                                    }}
                                    onClick={() =>
                                        navigate(USER_SETTINGS_ABS_ROUTE)
                                    }
                                >
                                    here
                                </u>
                                or close the modal to be redirected.
                            </div>
                        }
                    />
                </>
            )}
            <ModalFooter
                disableAdvance={
                    mutationErrorBanner ||
                    updateProfileMutationLoading ||
                    !form.dirty
                }
                advanceText="Save"
                handleAdvanceClick={handleSubmit}
                disablePrevious={
                    updateProfileMutationLoading || mutationErrorBanner
                }
                previousText="Cancel"
                handlePreviousClick={() => {
                    navigate(USER_SETTINGS_ABS_ROUTE)
                }}
                previousButtonCondition="default"
            />
        </>
    )
}

export default UpdatePhoneNumberFormComponent

const UpdatePhoneNumberSuccessComponent: FunctionComponent = () => {
    const navigate = useNavigate()

    return (
        <>
            <div className={styles.container}>
                <div className={styles.boldHeading}>
                    Your phone number has been verified.
                </div>
                <div className={styles.heading}>
                    You may now add alert subscriptions via text messages.
                </div>
            </div>
            <ModalFooter
                disableAdvance={false}
                advanceText="Close"
                handleAdvanceClick={() => {
                    navigate(USER_SETTINGS_ABS_ROUTE)
                }}
            />
        </>
    )
}
