import ToolTip from "../../../../shared/toolTip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons"
import classNames from "classnames"
import Select, { GroupBase, SingleValue, StylesConfig } from "react-select"
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons"
import Input from "../../../../shared/input"
import styles from "./form.module.scss"
import { LabelValueOptionType } from "../../../../../types/commonTypes"
import { FunctionComponent, ReactNode, useMemo } from "react"
import {
    AlertRuleFormErrorI,
    AlertRuleFormI,
} from "../alertRuleFormUtil/alertRuleFormTypes"
import {
    AlertRuleMetricDefinitionFieldsFragment,
    AlertRuleOperator,
    Unit,
} from "../../../../../generated/graphql"
import {
    LabelMetricEnumOptionType,
    getMetricOptionsFromMetrics,
    getWindowOptionsFromMetric,
} from "../alerts_GraphQL/alertRuleMetricFragment"
import { LabelDurationValueInputT } from "../../../../../util/duration/types"
import {
    DESCRIPTION_TT_TEXT,
    METRIC_TT_TEXT,
    THRESHOLD_GT_TT_TEXT,
    THRESHOLD_LT_TT_TEXT,
    THRESHOLD_OR_TT_TEXT,
    TIME_RANGE_TT_TEXT,
    TRHESHOLD_VARIANCE_TT_TEXT,
    createAlertRuleWindowInputOption,
} from "./formUtil"

export type AlertRuleFormType = "UPDATE" | "ADD"

interface ManageAlertRuleFormI {
    formAction: AlertRuleFormType
    metricOptions: LabelMetricEnumOptionType[]
    form: AlertRuleFormI | undefined
    alertRuleMetric: AlertRuleMetricDefinitionFieldsFragment | undefined
    handleMetricChange?: (e: SingleValue<LabelValueOptionType>) => void
    handleWindowChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
    handleRuleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleConditionUnitChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
    handleConditionThresholdChange: (
        e: React.ChangeEvent<HTMLInputElement>
    ) => void
    handleDescriptionChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    handleBlurFormInput: (form: AlertRuleFormI) => void
    formError: AlertRuleFormErrorI
}

const ManageAlertRuleForm: FunctionComponent<ManageAlertRuleFormI> = ({
    formAction,
    metricOptions,
    form,
    alertRuleMetric,
    handleMetricChange,
    handleWindowChange,
    handleRuleNameChange,
    handleDescriptionChange,
    handleConditionThresholdChange,
    handleConditionUnitChange,
    handleBlurFormInput,
    formError,
}) => {
    // constants
    const isUpdateForm = formAction === "UPDATE" ? true : false

    // windowOptions -- if the form (created by the alert rule) has a window value that is not in the window options from the template, we must add it to the input to accuretly display the form to the user.
    // an unfortunate side effect is: once a user clicks a different window option, they will lose this option from their drop down because it is not a template option. The template options are being used to populate the alert rule form.
    // this situation of the window not being in the template option is a rare edge case.
    const windowpOptions = useMemo(() => {
        // get template window options
        const templateWindowOptions = alertRuleMetric
            ? getWindowOptionsFromMetric(alertRuleMetric)
            : []

        // check if the form window value is in the template options
        const checkFormWindow = templateWindowOptions.findIndex((option) => {
            return option.value === form?.windowTotalSeconds.toString()
        })

        if (checkFormWindow === -1 && form?.windowTotalSeconds) {
            // if the form window value is not in the template options, add it to the list
            const newOption = createAlertRuleWindowInputOption(
                form.windowTotalSeconds
            )

            return [...templateWindowOptions, newOption]
        }

        // else  if the form window value is in the template options, return the template options
        return templateWindowOptions
    }, [alertRuleMetric, form?.windowTotalSeconds])

    return (
        <div className={styles.bodyContainer}>
            <div className={styles.metricContainer}>
                <div className={styles.metricTitleContainer}>
                    <div className={styles.flexRow}>
                        <span
                            className={classNames(
                                styles.titleText,
                                styles.marginRight4
                            )}
                        >
                            Metric:
                        </span>
                        <ToolTip
                            orientation="right"
                            toolTipChildren={<>{METRIC_TT_TEXT}</>}
                        >
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                color="#00AAFF"
                            />
                        </ToolTip>
                    </div>
                    <div className={styles.learnMore}>
                        <span
                            className={classNames(
                                styles.learnMore,
                                styles.marginRight4
                            )}
                        >
                            (learn more
                        </span>
                        <FontAwesomeIcon
                            icon={faArrowUpRightFromSquare}
                            color="#424242"
                        />
                        )
                    </div>
                </div>
                <div className={styles.metricInput}>
                    <Select
                        options={metricOptions}
                        isSearchable={true}
                        styles={reactSelectStyles}
                        placeholder={"- Select -"}
                        value={
                            alertRuleMetric
                                ? getMetricOptionsFromMetrics([alertRuleMetric])
                                : undefined
                        }
                        onChange={handleMetricChange}
                        isDisabled={isUpdateForm}
                        isClearable={isUpdateForm ? false : true}
                    />
                    <div
                        className={
                            isUpdateForm
                                ? styles.metricDescriptionDisabled
                                : styles.metricDescription
                        }
                    >
                        {alertRuleMetric?.description}
                    </div>
                </div>
            </div>
            <div className={styles.timeRangeContainer}>
                <div className={styles.timeRangeTitle}>
                    <div className={styles.flexRow}>
                        <span
                            className={classNames(
                                styles.titleText,
                                styles.marginRight4
                            )}
                        >
                            Time Range:
                        </span>
                        <ToolTip
                            orientation="right"
                            toolTipChildren={<>{TIME_RANGE_TT_TEXT}</>}
                        >
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                color="#00AAFF"
                            />
                        </ToolTip>
                    </div>
                </div>
                <Input
                    type="select"
                    options={windowpOptions}
                    onChange={handleWindowChange}
                    orientation="vertical"
                    value={form?.windowTotalSeconds}
                    specializedClass="noMessage"
                    inputCustomClass={styles.timeRangeInput}
                    disabled={!form} // disable if no form
                />
            </div>
            <RuleOptions
                form={form}
                handleDescriptionChange={handleDescriptionChange}
                handleRuleNameChange={handleRuleNameChange}
                handleConditionThresholdChange={handleConditionThresholdChange}
                handleConditionUnitChange={handleConditionUnitChange}
                handleBlurFormInput={handleBlurFormInput}
                formError={formError}
            />
        </div>
    )
}

export default ManageAlertRuleForm

export const CONDITION_THRESHOLD_INPUT_NAME = "threshold"
export const CONDITION_THRESHOLD_2_INPUT_NAME = "threshold2"

interface RuleOptionsI {
    form: AlertRuleFormI | undefined
    formError: AlertRuleFormErrorI
    handleRuleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    handleDescriptionChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    handleConditionUnitChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
    handleConditionThresholdChange: (
        e: React.ChangeEvent<HTMLInputElement>
    ) => void
    handleBlurFormInput: (form: AlertRuleFormI) => void
}

const RuleOptions: FunctionComponent<RuleOptionsI> = ({
    form,
    formError,
    handleDescriptionChange,
    handleRuleNameChange,
    handleConditionThresholdChange,
    handleConditionUnitChange,
    handleBlurFormInput,
}) => {
    return (
        <>
            <div
                className={classNames(
                    styles.ruleOptionsContainer,
                    !form && styles.disabled
                )}
            >
                <div
                    className={classNames(
                        styles.titleText,
                        !form && styles.disabled
                    )}
                >
                    Rule Options:
                </div>
                {form && (
                    <>
                        <div className={styles.ruleOptionTitleInputContainer}>
                            <div
                                className={classNames(
                                    styles.titleText,
                                    styles.flexRow,
                                    styles.ruleOptionTitle
                                )}
                            >
                                <span className={styles.marginRight4}>
                                    Rule Name
                                </span>
                            </div>
                            <div className={styles.ruleNameInput}>
                                <Input
                                    type="text"
                                    onChange={handleRuleNameChange}
                                    onBlur={() => handleBlurFormInput(form)}
                                    orientation="vertical"
                                    value={form.ruleName}
                                    message={
                                        formError.ruleName
                                            ? formError.ruleName
                                            : ""
                                    }
                                    specializedClass={
                                        formError.ruleName
                                            ? "danger"
                                            : "default"
                                    }
                                />
                            </div>
                        </div>
                        <ThresholdInputRow
                            form={form}
                            handleConditionThresholdChange={
                                handleConditionThresholdChange
                            }
                            handleConditionUnitChange={
                                handleConditionUnitChange
                            }
                            handleBlurFormInput={handleBlurFormInput}
                            formError={formError}
                        />
                        <div className={styles.ruleOptionTitleInputContainer}>
                            <div className={styles.ruleOptionTitle}>
                                <div className={styles.flexRow}>
                                    <span
                                        className={classNames(
                                            styles.titleText,
                                            styles.marginRight4
                                        )}
                                    >
                                        Description
                                    </span>
                                    <ToolTip
                                        orientation="right"
                                        toolTipChildren={
                                            <>{DESCRIPTION_TT_TEXT}</>
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faCircleInfo}
                                            color="#00AAFF"
                                        />
                                    </ToolTip>
                                </div>
                            </div>
                            <Input
                                type="textArea"
                                onChange={handleDescriptionChange}
                                orientation="vertical"
                                value={form.description}
                                specializedClass="noMessage"
                                inputCustomClass={styles.ruleDescriptionInput}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

interface ThresholdInputRowI {
    form: AlertRuleFormI
    formError: AlertRuleFormErrorI
    handleConditionUnitChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
    handleConditionThresholdChange: (
        e: React.ChangeEvent<HTMLInputElement>
    ) => void
    handleBlurFormInput: (form: AlertRuleFormI) => void
}

const ThresholdInputRow: FunctionComponent<ThresholdInputRowI> = ({
    form,
    formError,
    handleConditionUnitChange,
    handleConditionThresholdChange,
    handleBlurFormInput,
}) => {
    let operatorText = ""
    let unitText = ""
    let thresholdToolTipText = THRESHOLD_GT_TT_TEXT
    switch (form.condition.operator) {
        case AlertRuleOperator.GreaterThan:
            operatorText = "above:"
            thresholdToolTipText = THRESHOLD_GT_TT_TEXT
            break
        case AlertRuleOperator.LessThan:
            thresholdToolTipText = THRESHOLD_LT_TT_TEXT
            operatorText = "below:"
            break
        case AlertRuleOperator.OutsideRange: {
            operatorText = "outside range:"
            thresholdToolTipText = THRESHOLD_OR_TT_TEXT
            break
        }
        case AlertRuleOperator.VariancePercent: {
            thresholdToolTipText = TRHESHOLD_VARIANCE_TT_TEXT
            break
        }
        case AlertRuleOperator.Unspecified:
            operatorText = ""
            break
        default:
            operatorText = ""
            break
    }
    switch (form.condition.unit) {
        case Unit.Count:
            unitText = "count"
            break
        case Unit.Duration:
            unitText = "duration"
            break
        case Unit.Percentage:
            unitText = "percentage"
            break
        case Unit.StrokesPerMinute:
            unitText = "S.P.M"
            break
        case Unit.CountPerSecond:
            unitText = "count per second"
            break
        case Unit.Voltage:
            unitText = "voltage"
            break
        case Unit.Unspecified:
            unitText = ""
            break
        default:
            operatorText = ""
            break
    }

    let thresholdInputs: ReactNode
    const thresholdDurationOptions: LabelDurationValueInputT[] = [
        { label: "days", value: "d" },
        { label: "hrs", value: "h" },
        { label: "mins", value: "m" },
        { label: "secs", value: "s" },
    ]

    const isDuration =
        form.condition.unit && form.condition.unit === Unit.Duration

    switch (form.condition.operator) {
        case AlertRuleOperator.GreaterThan:
        case AlertRuleOperator.LessThan:
            {
                thresholdInputs = (
                    <>
                        <span className={styles.thresholdInstructionText}>
                            Alert when {unitText} is {operatorText}
                        </span>
                        <Input
                            type="number"
                            onChange={handleConditionThresholdChange}
                            orientation="horizontal"
                            value={form.condition.threshold}
                            inputCustomClass={styles.ruleOptionThreshold}
                            name={CONDITION_THRESHOLD_INPUT_NAME}
                            onBlur={() => {
                                handleBlurFormInput(form)
                            }}
                            message={
                                formError.threshold ? formError.threshold : ""
                            }
                            specializedClass={
                                formError.threshold ? "danger" : "default"
                            }
                            messageCustomClass={
                                formError.threshold
                                    ? styles.thresholdInputMessageDanger
                                    : styles.thresholdInputMessage
                            }
                        />
                        {/* Only show a time  unit when alert rule is  duration*/}
                        {isDuration && form.condition.thresholdTimeUnit && (
                            <Input
                                type="select"
                                onChange={handleConditionUnitChange}
                                options={thresholdDurationOptions}
                                orientation="horizontal"
                                value={form.condition.thresholdTimeUnit}
                                inputCustomClass={
                                    styles.ruleOptionConditionUnit
                                }
                                specializedClass="default"
                                onBlur={() => {
                                    handleBlurFormInput(form)
                                }}
                            />
                        )}
                    </>
                )
            }
            break
        case AlertRuleOperator.OutsideRange:
            thresholdInputs = (
                <>
                    <div
                        className={classNames(
                            styles.thersholdInstructionText_outsideRange
                        )}
                    >
                        Alert when {unitText} is {operatorText}
                    </div>
                    <div>
                        <Input
                            type="number"
                            onChange={handleConditionThresholdChange}
                            orientation="horizontal"
                            value={form.condition.threshold}
                            inputCustomClass={styles.ruleOptionThreshold}
                            name={CONDITION_THRESHOLD_INPUT_NAME}
                            onBlur={() => {
                                handleBlurFormInput(form)
                            }}
                            message={
                                formError.threshold
                                    ? formError.threshold
                                    : "Min"
                            }
                            specializedClass={
                                formError.threshold ? "danger" : "default"
                            }
                            messageCustomClass={
                                formError.threshold
                                    ? styles.thresholdInputMessageDanger
                                    : styles.thresholdInputMessage
                            }
                        />
                    </div>
                    <div>
                        <Input
                            type="number"
                            onChange={handleConditionThresholdChange}
                            orientation="horizontal"
                            value={form.condition.threshold2}
                            inputCustomClass={styles.ruleOptionThreshold}
                            name={CONDITION_THRESHOLD_2_INPUT_NAME}
                            onBlur={() => {
                                handleBlurFormInput(form)
                            }}
                            message={
                                formError.threshold2
                                    ? formError.threshold2
                                    : "Max"
                            }
                            specializedClass={
                                formError.threshold2 ? "danger" : "default"
                            }
                            messageCustomClass={
                                formError.threshold2
                                    ? styles.thresholdInputMessageDanger
                                    : styles.thresholdInputMessage
                            }
                        />
                    </div>
                    {/* Only show a time  unit when alert rule is  duration*/}
                    {isDuration && form.condition.thresholdTimeUnit && (
                        <div>
                            <Input
                                type="select"
                                onChange={handleConditionUnitChange}
                                options={thresholdDurationOptions}
                                orientation="horizontal"
                                value={form.condition.thresholdTimeUnit}
                                inputCustomClass={
                                    styles.ruleOptionConditionUnit
                                }
                                onBlur={() => {
                                    handleBlurFormInput(form)
                                }}
                                message={"Units"}
                                specializedClass={"default"}
                                messageCustomClass={
                                    styles.thresholdInputMessage
                                }
                            />
                        </div>
                    )}
                </>
            )
            break
        case AlertRuleOperator.VariancePercent:
            {
                thresholdInputs = (
                    <>
                        <span className={styles.thresholdInstructionText}>
                            Alert when variance is greater than
                        </span>
                        <Input
                            type="number"
                            onChange={handleConditionThresholdChange}
                            orientation="horizontal"
                            value={form.condition.threshold}
                            inputCustomClass={styles.ruleOptionThreshold}
                            name={CONDITION_THRESHOLD_INPUT_NAME}
                            onBlur={() => {
                                handleBlurFormInput(form)
                            }}
                            message={
                                formError.threshold ? formError.threshold : ""
                            }
                            specializedClass={
                                formError.threshold ? "danger" : "default"
                            }
                            messageCustomClass={
                                formError.threshold
                                    ? styles.thresholdInputMessageDanger
                                    : styles.thresholdInputMessage
                            }
                        />
                        <span className={styles.alignThresholdText}>
                            percent
                        </span>
                    </>
                )
            }
            break
        default:
            thresholdInputs = <></>
    }

    return (
        <>
            <div
                className={classNames(
                    styles.ruleOptionTitleInputContainer,
                    styles.thresholdInputContainer
                )}
            >
                <div className={styles.ruleOptionTitle}>
                    <div className={styles.flexRow}>
                        <span
                            className={classNames(
                                styles.titleText,
                                styles.marginRight4,
                                styles.alignThresholdText
                            )}
                        >
                            Threshold
                        </span>
                        <div className={classNames(styles.alignThresholdText)}>
                            <ToolTip
                                orientation="right"
                                toolTipChildren={<>{thresholdToolTipText}</>}
                            >
                                <FontAwesomeIcon
                                    icon={faCircleInfo}
                                    color="#00AAFF"
                                />
                            </ToolTip>
                        </div>
                    </div>
                </div>
                {thresholdInputs}
            </div>
        </>
    )
}

const reactSelectStyles: StylesConfig<
    LabelValueOptionType,
    false,
    GroupBase<LabelValueOptionType>
> = {
    // edit the styles here
    control: (provided, state) => ({
        ...provided,
        boxSizing: "border-box",
        outline: "none",
        padding: ".0 .25rem",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: state.isFocused ? "#64b5f6" : "#cfd8dc",
        borderRadius: "4px",
        backgroundColor: "white",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#37474f",
        height: "32px",
        boxShadow: state.isFocused
            ? "0px 0px 0px 3.2px rgba(0, 123, 255, 0.25)"
            : "none",
        opacity: state.isDisabled ? 0.75 : 1,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected
            ? "#64b5f6"
            : state.isFocused
            ? "#f5f5f5"
            : "transparent",

        color: state.isSelected ? "white" : "inherit",
    }),
}
