import { SubjectRole } from "../../../generated/graphql"

/**
 * Determines whether a user with a specific role can invite another user.
 * The intended use off this function is to be used as a check to show the "invite user" option in the navigation bar.
 *
 * @param {SubjectRole} role - The role of the user initiating the invitation.
 * @returns {boolean} - `true` if the user can invite another user, `false` otherwise.
 */
const canRoleInviteUser = (role: SubjectRole) => {
    if (role === SubjectRole.PpoAdmin || role === SubjectRole.OrgAdmin) {
        return true
    } else {
        return false
    }
}

/**
 * Check user role for access to add a well to well index.
 */
const canRoleAddWell = (role: SubjectRole) => {
    return [SubjectRole.PpoAdmin, SubjectRole.OrgAdmin].includes(role)
}

/*
 * Check user role for access select custom from range for iwp details chart
 */
const canRoleFetchCustomRangeForIwp = (role: SubjectRole) => {
    return [
        SubjectRole.PpoAdmin,
        SubjectRole.OrgAdmin,
        SubjectRole.OrgMember,
    ].includes(role)
}

/*
 * Check user role access to update well attributes
 * https://petropower.atlassian.net/wiki/spaces/TREX/pages/2798649345/Current+Role+Structure#Users-and-Roles
 */
const canRoleUpdateWellAttributes = (role: SubjectRole) => {
    return [SubjectRole.PpoAdmin, SubjectRole.OrgAdmin].includes(role)
}

/**
 * Check user role access to update well attributes
 * Specific request from product team to limit wiring mode update access to ppo admin && org admin on 1/17/2024
 */

const canRoleUpdateWellWiringMode = (role: SubjectRole) => {
    return [SubjectRole.PpoAdmin, SubjectRole.OrgAdmin].includes(role)
}

/**
 * Only PPO Admin  has access to use smart setting over ride values
 * */
const canRoleUseSmartSettingOverride = (role: SubjectRole) => {
    return [SubjectRole.PpoAdmin].includes(role)
}

const canRoleAddOrganization = (role: SubjectRole) => {
    return [SubjectRole.PpoAdmin].includes(role)
}

const canRoleUpdateOrganization = (role: SubjectRole) => {
    return [SubjectRole.PpoAdmin].includes(role)
}

const canRoleFetchInactiveUsers = (role: SubjectRole) => {
    return [SubjectRole.PpoAdmin].includes(role)
}

const canRoleAddAlertRule = (role: SubjectRole) => {
    return [SubjectRole.PpoAdmin, SubjectRole.OrgAdmin].includes(role)
}

/**
 * Only Org Admin & PPO Admin have access to see User Admin page
 */

const canRoleAccessUserAdminPage = (role: SubjectRole) => {
    return [SubjectRole.PpoAdmin, SubjectRole.OrgAdmin].includes(role)
}

/**
 * Only PPO Admin has access to see Org Admin page
 */

const canRoleAccessOrgAdminPage = (role: SubjectRole) => {
    return [SubjectRole.PpoAdmin].includes(role)
}

/**
 * Org Admin or higher can access subscriptions by rule
 */
const canRoleAccessSubscriptionsByRule = (role: SubjectRole) => {
    return [SubjectRole.PpoAdmin, SubjectRole.OrgAdmin].includes(role)
}

/**
 * Org Admin or higher can access subscriptions by user
 */
const canRoleAccessSubscriptionsByUser = (role: SubjectRole) => {
    return [SubjectRole.PpoAdmin, SubjectRole.OrgAdmin].includes(role)
}

/**
 * Org Admin or higher can add, update subscriptions, delete subscriptions for other users.
 */
const canRoleAdminSubscriptions = (role: SubjectRole) => {
    return [SubjectRole.PpoAdmin, SubjectRole.OrgAdmin].includes(role)
}

export {
    canRoleInviteUser,
    canRoleAddWell,
    canRoleFetchCustomRangeForIwp,
    canRoleUpdateWellAttributes,
    canRoleUpdateWellWiringMode,
    canRoleUseSmartSettingOverride,
    canRoleAddOrganization,
    canRoleUpdateOrganization,
    canRoleFetchInactiveUsers,
    canRoleAddAlertRule,
    canRoleAccessUserAdminPage,
    canRoleAccessOrgAdminPage,
    canRoleAccessSubscriptionsByRule,
    canRoleAccessSubscriptionsByUser,
    canRoleAdminSubscriptions,
}
