import { FunctionComponent, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import ModalFooter from "../../../../shared/modalFooter"
import { LabelValueOptionType } from "../../../../../types/commonTypes"
import { SingleValue } from "react-select"
import {
    AlertRuleFormConditionI,
    AlertRuleFormErrorI,
    AlertRuleFormI,
} from "../alertRuleFormUtil/alertRuleFormTypes"
import ManageAlertRuleForm, {
    CONDITION_THRESHOLD_2_INPUT_NAME,
    CONDITION_THRESHOLD_INPUT_NAME,
} from "../form"
import {
    AlertRuleMetric,
    AlertRuleMetricDefinitionFieldsFragment,
    Unit,
} from "../../../../../generated/graphql"
import { useMutation } from "@apollo/client"
import { getFragmentData } from "../../../../../generated"
import { DURATION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/duration"
import { findIndexByKeyInArray } from "../../../../../util/arrays/array"
import { ALERT_TEMPLATE_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRuleTemplate"
import { DurationUnitT } from "../../../../../util/duration/types"
import {
    getErrorsFromAlertRuleForm,
    isAlertRuleFormDisabled,
} from "../alertRuleFormUtil/validation"
import { getAlertRuleFormFromTemplate } from "../alerts_GraphQL/alertRuleTemplateFragment"
import { getMetricOptionsFromMetrics } from "../alerts_GraphQL/alertRuleMetricFragment"
import { ALERT_RULE_ADD } from "../../../../../graphql/mutations/alertRule_add"
import { getAddAlertRuleMutationVars } from "../alertRuleFormUtil/api"
import { useNotificationsContext } from "../../../context/notificationsContext"
import { GET_ALERT_RULES } from "../../../../../graphql/queries/rules"
import { NOTIFICATIONS_RULES_ABS_ROUTE } from "../../../../.."
import MutationErrorBanner from "../../../../shared/graphQlResponse"
import {
    parseInputToFloat,
    parseNumberInput,
} from "../../../../../util/InputValidation/inputValidation"
import AlertRuleFormActions from "../actions"

interface AddAlertRuleFormContainerI {
    metricsQueryRsp: AlertRuleMetricDefinitionFieldsFragment[]
}

const AddAlertRuleFormContainer: FunctionComponent<
    AddAlertRuleFormContainerI
> = ({ metricsQueryRsp }) => {
    // when adding a new rule - use the organization id selected via the dropdown in the notifications context
    const notificationsContext = useNotificationsContext()
    const selectedOrgId = notificationsContext.selectedOrganization.value

    const navigate = useNavigate()
    const [addAlertRuleMutation, { loading: mutationLoading }] =
        useMutation(ALERT_RULE_ADD)

    // state
    const [addRuleForm, setAddRuleForm] = useState<AlertRuleFormI | undefined>(
        undefined
    )

    const [addRuleFormError, setAddRuleFormError] =
        useState<AlertRuleFormErrorI>({
            ruleName: undefined,
            threshold: undefined,
            threshold2: undefined,
        })

    const [alertRuleMetric, setAlertRuleMetric] = useState<
        AlertRuleMetricDefinitionFieldsFragment | undefined
    >(undefined)

    const [mutationErrorBanner, setMutationErrorBanner] =
        useState<boolean>(false)

    // form handlers
    const handleMetricChange = (e: SingleValue<LabelValueOptionType>) => {
        const selectedMetric = e?.value as AlertRuleMetric | undefined

        if (!selectedMetric) {
            // user is clearing the input
            setAddRuleForm(undefined)
            setAlertRuleMetric(undefined)
            setAddRuleFormError({
                ruleName: undefined,
                threshold: undefined,
                threshold2: undefined,
            })
            return
        }

        // find index of newly selected alert rule metric in metrics
        const i = findIndexByKeyInArray(
            metricsQueryRsp,
            (obj) => obj.metric,
            e?.value as AlertRuleMetric
        )

        if (typeof i !== "number") {
            return
        }

        // use the first template as the default template
        const updatedMetric = metricsQueryRsp[i]
        const t = updatedMetric.templates && updatedMetric.templates[0]

        const defaultTemplate = getFragmentData(
            ALERT_TEMPLATE_FIELDS_FRAGMENT,
            t
        )

        if (!defaultTemplate) {
            return
        }

        // update add rule form
        const updatedForm = getAlertRuleFormFromTemplate(defaultTemplate)
        setAddRuleForm(updatedForm)
        // update metric
        setAlertRuleMetric(updatedMetric)
    }

    const handleWindowChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (!alertRuleMetric || !addRuleForm) {
            return
        }
        const targetValue = e.target.value

        // map alert template fields fragment into array
        const templates = alertRuleMetric.templates?.map((t) => {
            return getFragmentData(ALERT_TEMPLATE_FIELDS_FRAGMENT, t)
        })

        if (!templates) {
            return
        }

        // find index of newly selected window in templates
        const i = findIndexByKeyInArray(
            templates,
            (obj) =>
                getFragmentData(
                    DURATION_FIELDS_FRAGMENT,
                    obj.window
                ).totalSeconds.toString(),
            targetValue
        )

        if (typeof i !== "number") {
            return
        }
        const updatedTemplate = templates[i]

        // update form && clear errors
        // because the user is setting the form witha new template, the errors can be cleared.
        const updatedForm = getAlertRuleFormFromTemplate(updatedTemplate)
        setAddRuleForm(updatedForm)
        setAddRuleFormError({
            ruleName: undefined,
            threshold: undefined,
            threshold2: undefined,
        })
    }

    const handleRuleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!addRuleForm) {
            return
        }
        // if there is a form error and the user is updating a rule name input, we can clear it on valid input instead of waiting for blur
        // if there is no current error we should wait for blur
        // this creates a better user experience
        const updatedAddRuleForm = {
            ...addRuleForm,
            ruleName: e.target.value,
        }

        let updatedAddRuleFormError = { ...addRuleFormError }

        if (updatedAddRuleFormError.ruleName) {
            updatedAddRuleFormError =
                getErrorsFromAlertRuleForm(updatedAddRuleForm)
        }

        setAddRuleForm(updatedAddRuleForm)
        setAddRuleFormError(updatedAddRuleFormError)
    }

    const handleConditionUnitChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        if (!addRuleForm || addRuleForm.condition.unit !== Unit.Duration) {
            return
        }

        const targetUnit = e.target.value as DurationUnitT

        const updatedCondition: AlertRuleFormConditionI = {
            ...addRuleForm.condition,
            thresholdTimeUnit: targetUnit,
        }
        const updatedAddRuleForm = {
            ...addRuleForm,
            condition: updatedCondition,
        }

        // if there is a form error and the user is updating a threshold input, we can clear it on valid input instead of waiting for blur
        // if there is no current error we should wait for blur
        // this creates a better user experience

        let updatedaAddRuleFormError = { ...addRuleFormError }

        if (
            updatedaAddRuleFormError.threshold ||
            updatedaAddRuleFormError.threshold2
        ) {
            updatedaAddRuleFormError =
                getErrorsFromAlertRuleForm(updatedAddRuleForm)
        }

        //  set state for addRuleForm && addRuleFormError
        setAddRuleForm({
            ...addRuleForm,
            condition: updatedCondition,
        })
        setAddRuleFormError(updatedaAddRuleFormError)
    }

    const handleConditionThresholdChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (!addRuleForm) {
            return
        }

        // parse input to float or integer
        let targetValue: number | string
        switch (addRuleForm.condition.unit) {
            case Unit.Voltage:
                targetValue = parseInputToFloat(e)
                break
            case Unit.Count:
            case Unit.CountPerSecond:
            case Unit.Duration:
            case Unit.Percentage:
            case Unit.StrokesPerMinute:
            default:
                targetValue = parseNumberInput(e)
        }

        const updatedCondition: AlertRuleFormConditionI = {
            ...addRuleForm.condition,
        }

        if (e.target.name === CONDITION_THRESHOLD_INPUT_NAME) {
            updatedCondition.threshold = targetValue as number
        }
        if (e.target.name === CONDITION_THRESHOLD_2_INPUT_NAME) {
            updatedCondition.threshold2 = targetValue as number
        }

        const updatedAddRuleForm = {
            ...addRuleForm,
            condition: updatedCondition,
        }

        // if there is a form error and the user is updating a threshold input, we can clear it on valid input instead of waiting for blur
        // if there is no current for error we should wait for blur
        // this creates a better user experience

        let updatedAddRuleFormError = { ...addRuleFormError }
        if (
            updatedAddRuleFormError.threshold ||
            updatedAddRuleFormError.threshold2
        ) {
            updatedAddRuleFormError =
                getErrorsFromAlertRuleForm(updatedAddRuleForm)
        }

        // set state for addRuleForm && addRuleFormError
        setAddRuleForm({
            ...addRuleForm,
            condition: updatedCondition,
        })
        setAddRuleFormError(updatedAddRuleFormError)
    }

    const handleDescriptionChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        if (!addRuleForm) {
            return
        }
        setAddRuleForm({
            ...addRuleForm,
            description: e.target.value,
        })
    }

    // blur handlers
    const handleBlurFormInput = (form: AlertRuleFormI) => {
        const updatedAddRuleErr = getErrorsFromAlertRuleForm(form)
        setAddRuleFormError(updatedAddRuleErr)
    }

    // submission handler
    const handleSubmitAddAlertRule = () => {
        // can form be submitted
        const isDisabled = isAlertRuleFormDisabled(addRuleForm)

        if (!addRuleForm || !alertRuleMetric || isDisabled) {
            return
        }

        const mutationVariables = getAddAlertRuleMutationVars(
            addRuleForm,
            selectedOrgId
        )

        addAlertRuleMutation({
            variables: {
                input: mutationVariables,
            },
            refetchQueries: [
                {
                    query: GET_ALERT_RULES,
                    variables: {
                        RulesFilter: {
                            OrganizationIDs: [
                                notificationsContext.selectedOrganization.value,
                            ],
                        },
                    },
                },
            ],
        })
            .then(() => {
                // Upon successful mutation. Navigate to rules table
                navigate(NOTIFICATIONS_RULES_ABS_ROUTE)
            })
            .catch((error) => {
                console.error("Add alert rule mutation error:", error)
                setMutationErrorBanner(true)
            })
    }

    // constants
    const formAction = "ADD"
    const metricOptions = useMemo(() => {
        return getMetricOptionsFromMetrics(metricsQueryRsp)
    }, [metricsQueryRsp])

    return (
        <>
            <ManageAlertRuleForm
                formAction={formAction}
                form={addRuleForm}
                metricOptions={metricOptions}
                alertRuleMetric={alertRuleMetric}
                handleMetricChange={handleMetricChange}
                handleRuleNameChange={handleRuleNameChange}
                handleConditionThresholdChange={handleConditionThresholdChange}
                handleConditionUnitChange={handleConditionUnitChange}
                handleWindowChange={handleWindowChange}
                handleDescriptionChange={handleDescriptionChange}
                handleBlurFormInput={handleBlurFormInput}
                formError={addRuleFormError}
            />
            {!mutationErrorBanner && addRuleForm && (
                <>
                    <AlertRuleFormActions
                        ruleForm={addRuleForm}
                        formAction={formAction}
                    />
                </>
            )}
            {mutationErrorBanner && (
                <>
                    <MutationErrorBanner
                        message={
                            <div>
                                There was problem adding your alert rule. Click{" "}
                                <u
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        navigate(NOTIFICATIONS_RULES_ABS_ROUTE)
                                    }
                                >
                                    here
                                </u>{" "}
                                or close the modal to be redirected.
                            </div>
                        }
                    />
                </>
            )}
            <ModalFooter
                advanceText="Submit"
                handleAdvanceClick={handleSubmitAddAlertRule}
                disableAdvance={
                    isAlertRuleFormDisabled(addRuleForm) ||
                    mutationLoading ||
                    mutationErrorBanner
                }
            />
        </>
    )
}

export default AddAlertRuleFormContainer
