import { graphql } from "../../generated"

export const IDENTITY_FIELDS_FRAGMENT = graphql(`
    fragment IdentityFields on Identity {
        email
        emailVerified
        phoneNumber
        phoneNumberVerified
        active
        firstName
        lastName
        fullName
        zoneInfo
        smsConsent
        emailConsent
    }
`)
