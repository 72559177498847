/**
 * Checks if a given string represents a valid time in the format "HH:MM".
 *
 * @param {string} timeInput The input string representing a time.
 * @returns {boolean} Returns true if the input string is a valid time, false otherwise.
 */
const isValidTimeString = (timeInput: string): boolean => {
    const regex = /^(?:[01][0-9]|2[0-3]):[0-5][0-9]$/
    return regex.test(timeInput)
}

export { isValidTimeString }
