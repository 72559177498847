import { ReactNode } from "react"
import { AlertRuleMetric } from "../../generated/graphql"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    IconDefinition,
    faArrowDown,
    faArrowUp,
    faBoltLightning,
    faRouter,
    faStopwatch,
    faTriangleExclamation,
    faWaveform,
    faWifiSlash,
} from "@fortawesome/pro-solid-svg-icons"
import { faGaugeMax } from "@fortawesome/pro-regular-svg-icons"

const getIconForAlertRuleMetric = (status: AlertRuleMetric): ReactNode => {
    const colorMap = {
        danger: "#D40000",
        info: "#546E7A",
        warning: "#FFD700",
    }

    let iconColor: string
    let icon: IconDefinition

    switch (status) {
        // Documentation: https://www.figma.com/design/MVnCWl6wTgtlF32HCffp7n/4---Notifications?node-id=6392-44566&t=np322jGC5ngjsrdo-0
        // loss of power
        case AlertRuleMetric.LossOfPowerActive:
        case AlertRuleMetric.LossOfPowerCount:
        case AlertRuleMetric.LossOfPowerWindow:
            icon = faBoltLightning
            iconColor = colorMap.danger
            break
        // unexpected downtime
        case AlertRuleMetric.UnexpectedDowntimeWindow:
            icon = faArrowDown
            iconColor = colorMap.warning
            break
        // unexpected uptime
        case AlertRuleMetric.UnexpectedUptimeWindow:
            icon = faArrowUp
            iconColor = colorMap.info
            break
        // loss of connectivity
        case AlertRuleMetric.LossOfConnectivityActive:
        case AlertRuleMetric.LossOfConnectivityCount:
        case AlertRuleMetric.LossOfConnectivityWindow:
            icon = faRouter
            iconColor = colorMap.info
            break
        // out of control
        case AlertRuleMetric.OutOfControlActive:
            icon = faTriangleExclamation
            iconColor = colorMap.info
            break
        // sensor disconnect
        case AlertRuleMetric.SensorDisconnectDsActive:
        case AlertRuleMetric.SensorDisconnectDsCount:
        case AlertRuleMetric.SensorDisconnectDsWindow:
        case AlertRuleMetric.SensorDisconnectPrActive:
        case AlertRuleMetric.SensorDisconnectPrCount:
        case AlertRuleMetric.SensorDisconnectPrWindow:
            icon = faWifiSlash
            iconColor = colorMap.info
            break
        // run time
        case AlertRuleMetric.RuntimeRange:
        case AlertRuleMetric.RuntimeVariance:
            iconColor = colorMap.info
            icon = faWaveform
            break
        // run limit
        case AlertRuleMetric.RunLimitMaxCount:
        case AlertRuleMetric.RunLimitMinCount:
            iconColor = colorMap.info
            icon = faGaugeMax
            break
        // pumping speed or s.p.m
        case AlertRuleMetric.PumpingSpeedRange:
        case AlertRuleMetric.PumpingSpeedVariance:
            iconColor = colorMap.info
            icon = faStopwatch
            break
        case AlertRuleMetric.Unspecified:
        default:
            return <></>
    }

    return <FontAwesomeIcon icon={icon} color={iconColor} />
}

export { getIconForAlertRuleMetric }
