import { graphql } from "../../generated"

// Backend ticket: Rules need an organization ID to query by organizatoin.
// REASON: we want to add subscriptions to rules in our organization
// Backend ticket: Users needs an organization ID to query by organizatoin.
// REASON: we want to be able to fetch the users in specific organizations for admin add / update subscriptions.
export const SUBSCRIPTIONS_ADD_QUERY = graphql(`
    query SubscriptionAddQry($orgId: ID!) {
        rules {
            rules {
                id
                name
            }
        }
        wells(
            first: 1000
            filter: {
                organizationID: $orgId
                includeChildOrgs: true
                linked: true
            }
        ) {
            nodes {
                id
                name
            }
        }
    }
`)

export const SUBSCRIPTIONS_ADMIN_ADD_QRY = graphql(`
    query SubscriptionAdminAddQry($orgId: ID!) {
        rules(filter: { OrganizationIDs: [$orgId] }) {
            rules {
                id
                name
            }
        }
        wells(
            first: 1000
            filter: {
                organizationID: $orgId
                includeChildOrgs: true
                linked: true
            }
        ) {
            nodes {
                id
                name
            }
        }
        users(filter: { active: true }) {
            nodes {
                identity {
                    fullName
                    email
                    phoneNumber
                    phoneNumberVerified
                    smsConsent
                    emailVerified
                    emailConsent
                }
                organization {
                    id
                }
                id
            }
        }
    }
`)
