import { ReactNode, FunctionComponent } from "react"
import { Outlet } from "react-router-dom"
import { useQuery } from "@apollo/client"
import {
    ErrorMessage,
    SyncLoaderComponent,
} from "../../../../shared/graphQlResponse"
import styles from "./subscriptionsByUser.module.scss"
import { getFragmentData } from "../../../../../generated"
import { SUBSCRIPTION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/subscription"
import { GET_SUBSCRIPTIONS_FOR_ORG } from "../../../../../graphql/queries/subscriptionsForOrg"
import { getSubscriptionsAdminTableFromSubscriptionsFrag } from "../../subscriptions_GraphQL/subscriptionsFragment"
import SubscriptionsByUserTable from "./table/table"
import { useNotificationsContext } from "../../../context/notificationsContext"
import SubscriptionsEmptyState from "../../sharedComponents/table/emptyState"

const SubscriptionsByUser: FunctionComponent = () => {
    const selectedOrganization = useNotificationsContext().selectedOrganization
    const { error, loading, data } = useQuery(GET_SUBSCRIPTIONS_FOR_ORG, {
        variables: {
            input: {
                orgIDs: [selectedOrganization.value],
            },
        },
        fetchPolicy: "network-only",
    })

    const renderContent = (): ReactNode => {
        if (error) {
            console.error("error", error)
            return (
                <ErrorMessage
                    outSideDivStyle={styles.error}
                    message="There was a problem fetching subscriptions by user."
                />
            )
        }

        if (loading) {
            return <SyncLoaderComponent outSideDivStyle={styles.loader} />
        }

        if (data) {
            const subscriptions = data.subscriptionsForOrgs.subscriptions.map(
                (sub) => getFragmentData(SUBSCRIPTION_FIELDS_FRAGMENT, sub)
            )

            const formatted =
                getSubscriptionsAdminTableFromSubscriptionsFrag(subscriptions)

            if (formatted.length === 0) {
                return (
                    <SubscriptionsEmptyState
                        viewerAction="admin"
                        message="No subscriptions found"
                    />
                )
            }

            return <SubscriptionsByUserTable data={formatted} />
        }

        return <></>
    }

    return (
        <>
            <Outlet />
            <div className={styles.container}>{renderContent()}</div>
        </>
    )
}
export default SubscriptionsByUser
