import { FunctionComponent } from "react"
import styles from "./cell.module.scss"
import MultiSelect, {
    MultiSelectOptionT,
} from "../../../../shared/input/react-select/multiSelect"
import { SubscriptionsAdminWellNameSep } from "../../adminSubscriptionsViews/tableTypes"

interface SubscriberReceiveNotificationsCellI {
    accept: boolean
}
const SubscriberReceiveNotificationsCell: FunctionComponent<
    SubscriberReceiveNotificationsCellI
> = ({ accept: boolean }) => {
    if (boolean) {
        return <span className={styles.receiveNotifications}>ON</span>
    }
    return <span className={styles.receiveNotifications}>OFF</span>
}

interface SubscriberWellsCellI {
    wells: string
}
const SubscriberWellsCell: FunctionComponent<SubscriberWellsCellI> = ({
    wells,
}) => {
    const wellsArray = wells.split(SubscriptionsAdminWellNameSep)
    const multiSelectOptions: MultiSelectOptionT[] = wellsArray.map((well) => {
        return { label: well, value: well, isDisabled: true, isSelected: true }
    })

    return (
        <MultiSelect
            onChange={() => {
                return
            }}
            options={multiSelectOptions}
            menuPortalTarget={document.body}
            isClearable={false}
            styles={{
                container: (provided) => ({
                    ...provided,
                    width: 400,
                }),
                control: (provided) => ({
                    ...provided,
                    width: "100%", // This ensures the control fills the container
                    minHeight: "30px",
                    height: "30px",
                    background: "#CACACA",
                    color: "#fff",
                    fontWeight: "500",
                    borderColor: "#909090",
                }),
                valueContainer: (provided) => ({
                    ...provided,
                    height: "30px",
                    padding: "0 6px",
                }),
                input: (provided) => ({
                    ...provided,
                    margin: "0px",
                }),
                indicatorSeparator: () => ({
                    display: "none",
                }),
                indicatorsContainer: (provided) => ({
                    ...provided,
                    height: "30px",
                }),
                option: (provided) => ({
                    ...provided,
                    backgroundColor: "#fff",
                    color: "#000",
                    ":hover": {
                        backgroundColor: "none",
                    },
                }),
                dropdownIndicator: (provided) => ({
                    ...provided,
                    color: "#1E88E5",
                    ":hover": {
                        color: "#1E88E5",
                    },
                }),
            }}
        />
    )
}

export { SubscriberReceiveNotificationsCell, SubscriberWellsCell }
