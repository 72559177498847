import { FunctionComponent } from "react"
import ModalHeader from "../../shared/modalHeader"
import { TrexNavigator } from "../../../classes/navigator/navigator"
import { useNavigate } from "react-router-dom"
import { USER_SETTINGS_ABS_ROUTE } from "../../.."
import { useViewerContext } from "../../../context/ViewerContext"
import UpdateEmailFormComponent from "./form"

const UpdateProfileEmail: FunctionComponent = () => {
    const navigate = useNavigate()
    const { getViewer } = useViewerContext()
    const { identity, userId } = getViewer()

    // query
    // query email for notifications and allowNotifications preference
    // constants
    const modalTitle = identity.email ? "Update Email" : "Add Email" // TO DO: - use email from query result

    return (
        <>
            <ModalHeader
                title={modalTitle}
                trexNavigator={
                    new TrexNavigator(
                        { navigateTo: USER_SETTINGS_ABS_ROUTE },
                        navigate
                    )
                }
            />

            <UpdateEmailFormComponent userId={userId} email={identity.email} />
        </>
    )
}

export default UpdateProfileEmail
