import { FunctionComponent, ReactNode, useState } from "react"
import styles from "./deleteSubscription.module.scss"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import ModalHeader from "../../../../shared/modalHeader"
import { TrexNavigator } from "../../../../../classes/navigator/navigator"
import ModalFooter from "../../../../shared/modalFooter"
import { useMutation, useQuery } from "@apollo/client"
import {
    ErrorMessage,
    SyncLoaderComponent,
    EmptyState,
} from "../../../../shared/graphQlResponse"
import { SUBSCRIPTION_FOR_ID } from "../../../../../graphql/queries/subscriptionForIDs"
import { SUBSCRIPTIONS_DELETE } from "../../../../../graphql/mutations/subscriptions_delete"
import { GET_SUBSCRIPTIONS_FOR_ORG } from "../../../../../graphql/queries/subscriptionsForOrg"
import { GET_SUBSCRIPTIONS_FOR_USER } from "../../../../../graphql/queries/subscriptionsForUser"
import { getFragmentData } from "../../../../../generated"
import { SUBSCRIPTION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/subscription"
import { ALERT_RULE_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRule"
import { ManageSubscriptionViewerActionT } from "../manageSubscriptionFormUtil/types"
import { SubscriptionFieldsFragment } from "../../../../../generated/graphql"
import { SUBJECT_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/subject"
import { IDENTITY_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/Identity"
import { navigateAfterMutation } from "../../../navigate"

interface DeleteSubscriptionI {
    viewerAction: ManageSubscriptionViewerActionT
}

const DeleteSubscription: FunctionComponent<DeleteSubscriptionI> = ({
    viewerAction,
}) => {
    const navigate = useNavigate()

    const { key: locationKey, pathname } = useLocation()
    const urlSlugs = useParams()
    const subscriptionId = urlSlugs.subscriptionId as string

    //  Query to get subscription details
    const { error, loading, data } = useQuery(SUBSCRIPTION_FOR_ID, {
        variables: {
            AlertSubscriptionsForIDsInput: { ids: [subscriptionId] },
        },
        fetchPolicy: "network-only",
    })

    // Mutation to delete subscription
    const [deleteSubscriptionMutation, { loading: mutationLoading }] =
        useMutation(SUBSCRIPTIONS_DELETE, {
            variables: {
                AlertSubscriptionsDeleteInput: { ids: [subscriptionId] },
            },
        })

    // State
    const [deleteSubscriptionError, setDeleteSubscriptionError] =
        useState(false)

    // Handlers
    const handleSubmitDelete = async () => {
        await deleteSubscriptionMutation({
            refetchQueries: [
                GET_SUBSCRIPTIONS_FOR_USER,
                GET_SUBSCRIPTIONS_FOR_ORG,
            ],
        })
            .then(() => {
                const nav = navigateAfterMutation(pathname)
                navigate(nav.to, nav.options)
            })
            .catch((error) => {
                console.error("Delete subscription mutation error: ", error)
                setDeleteSubscriptionError(true)
            })
    }

    // Subscription data
    const subscription = getFragmentData(
        SUBSCRIPTION_FIELDS_FRAGMENT,
        data?.subscriptionsForIDs.subscriptions[0]
    )

    // Constants
    const isSubmitDisabled =
        loading ||
        Boolean(error) ||
        mutationLoading ||
        deleteSubscriptionError ||
        !subscription

    // Rendering
    let deleteSubscriptionComponent: ReactNode

    if (loading) {
        deleteSubscriptionComponent = (
            <SyncLoaderComponent outSideDivStyle={styles.queryLoading} />
        )
    } else if (error) {
        deleteSubscriptionComponent = (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="We had a problem while fetching your subscription information."
            />
        )
    } else if (deleteSubscriptionError) {
        deleteSubscriptionComponent = (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="There was a problem deleting your subscription."
            />
        )
    } else if (!subscription) {
        deleteSubscriptionComponent = (
            <EmptyState outSideDivStyle={styles.queryEmpty}>
                <> No subscription with this id was found.</>
            </EmptyState>
        )
    } else if (subscription && !deleteSubscriptionError) {
        // the api will not return the subscriptinon if the viewer does not have access to the subscription.
        deleteSubscriptionComponent = (
            <DeleteSubscriptionBody
                viewerAction={viewerAction}
                subscription={subscription}
            />
        )
    }

    return (
        <div className={styles.container}>
            <ModalHeader
                title={"Delete Subscription"}
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: locationKey,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
            />
            <div className={styles.body}>{deleteSubscriptionComponent}</div>
            <ModalFooter
                advanceText="Confirm"
                handleAdvanceClick={handleSubmitDelete}
                disableAdvance={isSubmitDisabled}
                previousText="Cancel"
                handlePreviousClick={() => {
                    navigate(-1)
                }}
                disablePrevious={isSubmitDisabled}
            />
        </div>
    )
}

export default DeleteSubscription

interface DeleteSubscriptionBodyI {
    subscription: SubscriptionFieldsFragment
    viewerAction: ManageSubscriptionViewerActionT
}

const DeleteSubscriptionBody: FunctionComponent<DeleteSubscriptionBodyI> = ({
    subscription,
    viewerAction,
}) => {
    const subscriptionAlertRule = getFragmentData(
        ALERT_RULE_FIELDS_FRAGMENT,
        subscription?.alertRule
    )

    let subscriberFullName = ""
    if (viewerAction === "admin") {
        const subscriber = getFragmentData(
            SUBJECT_FIELDS_FRAGMENT,
            subscription?.subscriber
        )

        const identity = getFragmentData(
            IDENTITY_FIELDS_FRAGMENT,
            subscriber.identity
        )

        subscriberFullName = identity?.fullName || ""
    }

    return (
        <>
            <div className={styles.title}>
                Are you sure you want to delete this subscription?
            </div>
            {viewerAction === "admin" && (
                <div className={styles.subscriptionDetails}>
                    <span className={styles.label}>Subscriber:</span>
                    <span>{subscriberFullName}</span>
                </div>
            )}
            <div className={styles.subscriptionDetails}>
                <span className={styles.label}>Rule:</span>
                <span>{subscriptionAlertRule?.name}</span>
            </div>
        </>
    )
}
