import { getFragmentData } from "../../../../../generated"
import { AlertRuleTemplateFieldsFragment } from "../../../../../generated/graphql"
import { ALERT_RULE_CONDITION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRuleCondition"
import { DURATION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/duration"
import { AlertRuleFormI } from "../alertRuleFormUtil/alertRuleFormTypes"
import { getInitialAlertRuleFormCondition } from "./alertRuleConditionFragment"

const getAlertRuleFormFromTemplate = (
    template: AlertRuleTemplateFieldsFragment
): AlertRuleFormI => {
    const windowFragment = getFragmentData(
        DURATION_FIELDS_FRAGMENT,
        template.window
    )
    const conditionFragment = getFragmentData(
        ALERT_RULE_CONDITION_FIELDS_FRAGMENT,
        template.condition
    )

    // get condition object for form
    const formCondition = getInitialAlertRuleFormCondition(conditionFragment)

    const obj: AlertRuleFormI = {
        description: template.description,
        metric: template.metric,
        ruleName: template.name,
        condition: formCondition,
        windowTotalSeconds: windowFragment.totalSeconds,
        evaluationGroup: template.evaluationGroup,
    }

    return obj
}

export { getAlertRuleFormFromTemplate }
