import React, { FunctionComponent, useState } from "react"
import styles from "./doNotDisturbModal.module.scss"
import ModalHeader from "../../shared/modalHeader"
import ModalFooter from "../../shared/modalFooter"
import { TrexNavigator } from "../../../classes/navigator/navigator"
import { useNavigate } from "react-router-dom"
import { Checkbox } from "../../shared/selectionControls"
import Input from "../../shared/input"
import { DndFormErrorsI, DndFormI, DndSelectedDaysI } from "./types"
import { USER_SETTINGS_ABS_ROUTE } from "../../.."
import { isValidTimeString } from "../../../util/datesAndTimes/timeString/timeString"

const DoNotDisturbModal: FunctionComponent = () => {
    const navigate = useNavigate()

    const [dndFormState, setDndFormState] = useState<DndFormI>({
        selectedDays: {
            su: false,
            mo: false,
            tu: false,
            we: false,
            th: false,
            fr: false,
            sa: false,
        },
        start: "00:00",
        end: "00:00",
    })

    const [dndFormErrors, setDndFormErrors] = useState<DndFormErrorsI>({
        start: undefined,
        end: undefined,
    })

    // form handlers
    const handleCheckboxClick = (selectedDayKey: keyof DndSelectedDaysI) => {
        const updatedValue = !dndFormState.selectedDays[selectedDayKey]

        // update selected days
        const updatedSelectedDays = {
            ...dndFormState.selectedDays,
            [selectedDayKey]: updatedValue,
        }

        setDndFormState({
            ...dndFormState,
            selectedDays: updatedSelectedDays,
        })
    }

    const handleChangeStart = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDndFormState({
            ...dndFormState,
            start: e.target.value,
        })
    }

    const handleChangeEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDndFormState({
            ...dndFormState,
            end: e.target.value,
        })
    }

    // blur handler
    const handleTimeInputBlur = () => {
        const updatedErrors = getFormErrors(dndFormState)
        setDndFormErrors({
            ...updatedErrors,
        })
    }

    // submission handler
    const handelSubmit = () => {
        console.log("submit unimplemented", dndFormState)
        // on mutation success navigate to user settings
        // on mutation failure show error banner
    }

    return (
        <>
            <ModalHeader
                title="Do Not Disturb Hours"
                trexNavigator={
                    new TrexNavigator(
                        { navigateTo: USER_SETTINGS_ABS_ROUTE },
                        navigate
                    )
                }
            />
            <div className={styles.container}>
                <div className={styles.infoHeading}>
                    Define the times of the day that you would like to delay
                    receiving of any notifications
                </div>
                <div className={styles.datePickerContainer}>
                    <Input
                        type="time"
                        label="Start Time:"
                        customLabelClass={styles.datePickerLabel}
                        inputCustomClass={styles.marginRight24}
                        orientation="horizontal"
                        value={dndFormState.start}
                        onChange={handleChangeStart}
                        onBlur={handleTimeInputBlur}
                        message={
                            dndFormErrors.start
                                ? dndFormErrors.start
                                : undefined
                        }
                        specializedClass={
                            dndFormErrors.start ? "danger" : "default"
                        }
                    />
                    <Input
                        type="time"
                        label="End Time:"
                        customLabelClass={styles.datePickerLabel}
                        orientation="horizontal"
                        value={dndFormState.end}
                        onChange={handleChangeEnd}
                        onBlur={handleTimeInputBlur}
                    />
                </div>
                <div className={styles.toggleHeading}>
                    Select which days of the week you want to apply the Do Not
                    Disturb Hours on:
                </div>
                <div className={styles.toggleContainer}>
                    {Object.entries(dndFormState.selectedDays).map(
                        ([day, checked]) => (
                            <Checkbox
                                key={day}
                                checkboxLabel={getDayLabel(day)}
                                checked={checked}
                                handleCheckBoxClick={() =>
                                    handleCheckboxClick(
                                        day as keyof DndSelectedDaysI
                                    )
                                }
                                disabled={false}
                            />
                        )
                    )}
                </div>
            </div>
            <ModalFooter
                disableAdvance={false}
                advanceText="Save"
                handleAdvanceClick={handelSubmit}
                disablePrevious={false}
                previousText="Cancel"
                handlePreviousClick={() => {
                    navigate(USER_SETTINGS_ABS_ROUTE)
                }}
                previousButtonCondition="default"
            />
        </>
    )
}

export default DoNotDisturbModal

// utils
const getDayLabel = (day: string) => {
    switch (day) {
        case "su":
            return "Sunday"
        case "mo":
            return "Monday"
        case "tu":
            return "Tuesday"
        case "we":
            return "Wednesday"
        case "th":
            return "Thursday"
        case "fr":
            return "Friday"
        case "sa":
            return "Saturday"
        default:
            return ""
    }
}

const getFormErrors = (form: DndFormI): DndFormErrorsI => {
    // to do:
    // this function will accept form
    // once we know what the api wants as inputs consider how to handle cases like.
    // 8pm - 6am

    const errors: DndFormErrorsI = {
        start: undefined,
        end: undefined,
    }

    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time#handling_browser_support
    // older versions of Safari and a few other, less common, browsers don't support time inputs natively
    // we can assure we are getting the correct format by checking the input value against a regex
    const startValidFormat = isValidTimeString(form.start)
    if (!startValidFormat) {
        errors.start = "Format must be hh:mm"
    }

    const endValidFormat = isValidTimeString(form.end)
    if (!endValidFormat) {
        errors.end = "Format must be hh:mm"
    }

    return errors
}
