import { FunctionComponent, ReactNode, useEffect, useState } from "react"
import { ManageSubscriptionFormType } from "../../manageSubscriptions/form"
import { useNavigate } from "react-router-dom"
import { ApolloError, useLazyQuery, useQuery } from "@apollo/client"
import styles from "./subscriptionsActions.module.scss"
import Button from "../../../../shared/button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faX } from "@fortawesome/pro-solid-svg-icons"
import { RULE_PREVIEW_EVALUATE } from "../../../../../graphql/queries/rulePreviewEvaluate"
import { getFragmentData } from "../../../../../generated"
import { ALERT_RULE_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRule"
import { getAlertRuleFormFromAlertRuleFragment } from "../../../rules/manageAlertRule/alerts_GraphQL/alertRuleFragment"
import { getRulePreviewEvaluateQueryVars } from "../../../rules/manageAlertRule/alertRuleFormUtil/api"
import { RulePreviewEvaluateQuery } from "../../../../../generated/graphql"
import {
    EmptyState,
    ErrorMessage,
    SyncLoaderComponent,
} from "../../../../shared/graphQlResponse"
import { ALERT_EVENT_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertEvent"
import classNames from "classnames"
import { WELL_IDENTIFICATION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/wellIdentification"
import { AlertRuleFormI } from "../../../rules/manageAlertRule/alertRuleFormUtil/alertRuleFormTypes"
import { GET_ALERT_RULES_BY_ID } from "../../../../../graphql/queries/rulesById"
import { useNotificationsContext } from "../../../context/notificationsContext"
import { MultiSelectOptionT } from "../../../../shared/input/react-select/multiSelect"
import {
    NOTIFICATIONS_ADMIN_DELETE_SUBSCRIPTION_CHILD,
    NOTIFICATIONS_DELETE_SUBSCRIPTION_CHILD,
} from "../../../../.."
import { formatAlertMetricValueWithUnit } from "../../../rules/manageAlertRule/alerts_GraphQL/alertRuleMetricFragment"
import { ALERT_RULE_CONDITION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRuleCondition"

interface ManageSubscriptionsActionsI {
    formType: ManageSubscriptionFormType
    alertRuleId: string
    selectedSubscribedWells: MultiSelectOptionT[]
}

const ManageSubscriptionsActions: FunctionComponent<
    ManageSubscriptionsActionsI
> = ({ formType, alertRuleId, selectedSubscribedWells }) => {
    const navigate = useNavigate()

    // preview should respect the organization selector for notifications context
    const notificationsContext = useNotificationsContext()
    const selectedOrganizationId =
        notificationsContext.selectedOrganization.value

    // state
    const [showPreview, setShowPreview] = useState<boolean>(false)

    // get rule by id from subscription so that we can evaluate the rule.
    const {
        error: ruleByIdError,
        loading: ruleByIdLoading,
        data: ruleByIdData,
    } = useQuery(GET_ALERT_RULES_BY_ID, {
        variables: {
            input: {
                ids: [alertRuleId],
            },
        },
        fetchPolicy: "network-only",
    })

    // preview query - can use rule preview evaluate and filter out wells that are not selected.
    // The backend will be adding a filter by well so we do not have to do this filtering on the fe.
    const [
        previewSubscriptionForAlertRule,
        { error: previewError, loading: previewLoading, data: previewData },
    ] = useLazyQuery(RULE_PREVIEW_EVALUATE)

    // constants
    const previewButtonText = showPreview
        ? "Refresh Preview"
        : "Preview Current Alerting State"

    const isUpdateForm = formType === "UpdateAdmin" || formType === "UpdateSelf"
    const selectedWellIds = selectedSubscribedWells.map((well) => well.value)

    // get rule form
    let alertRuleForm: AlertRuleFormI | undefined = undefined
    if (ruleByIdData) {
        const alertRuleFragment = getFragmentData(
            ALERT_RULE_FIELDS_FRAGMENT,
            ruleByIdData.rulesByIDs.rules?.[0]
        )

        if (alertRuleFragment) {
            alertRuleForm =
                getAlertRuleFormFromAlertRuleFragment(alertRuleFragment)
        }
    }

    useEffect(() => {
        // reset the preview state when selected wells change.
        setShowPreview(false)
    }, [selectedSubscribedWells])

    return (
        <>
            <div className={styles.container}>
                <div className={styles.actionContainer}>
                    <div className={styles.actionContainerLeft}>
                        <Button
                            handleButtonClick={() => {
                                if (ruleByIdLoading || ruleByIdError) {
                                    // ruleById is loading, then the button is disabled
                                    return
                                } else {
                                    setShowPreview(true)
                                    if (!alertRuleForm) {
                                        return undefined
                                    }
                                    previewSubscriptionForAlertRule({
                                        variables: {
                                            input: getRulePreviewEvaluateQueryVars(
                                                alertRuleForm,
                                                selectedOrganizationId
                                            ),
                                        },
                                        // network only so we always get the latest data
                                        fetchPolicy: "network-only",
                                    })
                                }
                            }}
                            status="primary"
                            condition="default"
                            customButtonClassName={styles.marginRight16}
                            disabled={
                                previewLoading ||
                                ruleByIdLoading ||
                                Boolean(ruleByIdError)
                            }
                        >
                            {previewButtonText}
                        </Button>
                        {isUpdateForm && (
                            <Button
                                handleButtonClick={() => {
                                    const route =
                                        formType === "UpdateAdmin"
                                            ? `../${NOTIFICATIONS_ADMIN_DELETE_SUBSCRIPTION_CHILD}`
                                            : `../${NOTIFICATIONS_DELETE_SUBSCRIPTION_CHILD}`

                                    navigate(route, {
                                        relative: "path",
                                    })
                                }}
                                status="primary"
                                condition="alert"
                            >
                                Delete Subscription
                            </Button>
                        )}
                    </div>
                    {showPreview && (
                        <Button
                            handleButtonClick={() => setShowPreview(false)}
                            status="secondary"
                            condition="default"
                        >
                            <FontAwesomeIcon color="#455a64" icon={faX} />
                        </Button>
                    )}
                </div>
                {(showPreview && alertRuleForm && (
                    <DisplayAlertState
                        ruleForm={alertRuleForm}
                        error={previewError}
                        loading={previewLoading}
                        data={previewData}
                        selectedWells={selectedWellIds}
                    />
                )) || (
                    <div className={styles.previewDescription}>
                        Preview the selected wells currently alerting under the
                        configured rule.
                    </div>
                )}
            </div>
        </>
    )
}

export default ManageSubscriptionsActions

interface DisplayAlertStateI {
    error: ApolloError | undefined
    loading: boolean
    data: RulePreviewEvaluateQuery | undefined
    ruleForm: AlertRuleFormI
    selectedWells: string[]
}

const DisplayAlertState: FunctionComponent<DisplayAlertStateI> = ({
    error,
    loading,
    data,
    ruleForm,
    selectedWells,
}) => {
    let body: ReactNode = <></>

    if (error) {
        console.error(error)
        body = (
            <ErrorMessage
                outSideDivStyle={styles.error}
                message="There was an issue previewing your subscription. Please try again."
            />
        )
    } else if (loading) {
        body = <SyncLoaderComponent outSideDivStyle={styles.loader} />
    } else if (data) {
        if (data.rulePreviewEvaluate.events.length === 0) {
            body = <EmptyAlertsEvents />
        } else {
            // filter for events on selected wells. As noted above, this functionality will be moved to the backend.
            const e = getFragmentData(
                ALERT_EVENT_FIELDS_FRAGMENT,
                data.rulePreviewEvaluate.events
            )

            const events = e.filter((event) => {
                const eventWell = getFragmentData(
                    WELL_IDENTIFICATION_FIELDS_FRAGMENT,
                    event.well
                )
                return selectedWells.includes(eventWell.id)
            })

            body = (
                <div className={styles.previewDataContainer}>
                    <div className={styles.previewRowContainer}>
                        <div
                            className={classNames(
                                styles.previewTitle,
                                styles.padWellName
                            )}
                        >
                            Well Name
                        </div>
                        <div className={styles.previewTitle}>
                            Current Metric Value
                        </div>
                    </div>
                    {events.map((event) => {
                        // get well name for display
                        const well = getFragmentData(
                            WELL_IDENTIFICATION_FIELDS_FRAGMENT,
                            event.well
                        )

                        // get trigger value with unit for display
                        const eventAlertRule = getFragmentData(
                            ALERT_RULE_FIELDS_FRAGMENT,
                            event.alertRule
                        )

                        const alertRuleCondition = getFragmentData(
                            ALERT_RULE_CONDITION_FIELDS_FRAGMENT,
                            eventAlertRule.condition
                        )

                        // if the previewed alert rule is a condition of type duration -- set the trigger value to the same duration unit as the threshold on the rule that is being previewed
                        const targetDurationUnit =
                            ruleForm.condition.thresholdTimeUnit

                        const displayTriggerValueWithUnit =
                            formatAlertMetricValueWithUnit(
                                event.triggeredValue,
                                alertRuleCondition.unit,
                                targetDurationUnit
                            )

                        return (
                            <div
                                key={well.id}
                                className={styles.previewRowContainer}
                            >
                                <div className={styles.padWellName}>
                                    {well.name}
                                </div>
                                <div>{displayTriggerValueWithUnit}</div>
                            </div>
                        )
                    })}
                </div>
            )
        }
    }

    return <div className={styles.previewOuterContainer}>{body}</div>
}

const EmptyAlertsEvents: FunctionComponent = () => {
    return (
        <EmptyState outSideDivStyle={styles.emptyStateContainer}>
            <div className={styles.emptyTitle}>
                None of the selected wells are alerting under the configured
                rule.
            </div>
        </EmptyState>
    )
}
