import {
    FunctionComponent,
    useState,
    useEffect,
    ReactNode,
    useRef,
    useContext,
} from "react"
import RadixDropDownMenu from "../shared/dropDown/radixDropDownMenu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faEllipsis } from "@fortawesome/pro-solid-svg-icons"
import NavigationControls from "../shared/navigationControls"
import { useAuth } from "react-oidc-context"
import Button from "../shared/button"
import { useLocation, useNavigate } from "react-router-dom"
import styles from "./navigationBar.module.scss"
import {
    canRoleInviteUser,
    canRoleAccessUserAdminPage,
    canRoleAccessOrgAdminPage,
} from "../../util/rolePermissions/canRole"
import { useViewerContext } from "../../context/ViewerContext"
import { getNavigationMenuOptionsByRole } from "../../util/rolePermissions/getByRole"
import { getInitials } from "../../util/users"
import { SubjectRole } from "../../generated/graphql"
import { Item } from "@radix-ui/react-dropdown-menu"
import { debounce } from "../../util/debounce"
import { NavigationControlDropdown } from "../shared/dropDown"
import { NOTIFICATIONS_ABS_ROUTE, USER_SETTINGS_ABS_ROUTE } from "../.."
import { getNavigationBarControlsByRole } from "../../util/rolePermissions/getByRole"
import { ConfigContext } from "../../context/ConfigContexts"

// Enable Feature flag. Remove notificaitons from desktop and mobile views.
// See <StandardAppControls/> to remove feature flag and show notification navigational controls.

const NavigationBar: FunctionComponent = () => {
    const { getViewer } = useViewerContext()
    const { role: viewerRole, identity } = getViewer()
    const initials = getInitials(identity.firstName, identity.lastName)
    const initialShowMobileMenu = window.innerWidth <= 768 ? true : false
    const [isMobileView, setIsMobileView] = useState(initialShowMobileMenu)

    useEffect(() => {
        const debouncedHandleScreenResize = debounce(() => {
            setIsMobileView(window.innerWidth <= 768)
        }, 200)

        window.addEventListener("resize", debouncedHandleScreenResize, true)
        return () => {
            window.removeEventListener(
                "resize",
                debouncedHandleScreenResize,
                true
            )
        }
    }, [])

    return (
        <div className={styles.container}>
            <AppControls isMobileView={isMobileView} viewerRole={viewerRole} />
            <ManagementControls
                userInitials={initials}
                viewerRole={viewerRole}
            />
        </div>
    )
}

interface AppControlsI {
    isMobileView: boolean
    viewerRole: SubjectRole
}

const AppControls: FunctionComponent<AppControlsI> = ({
    isMobileView,
    viewerRole,
}) => {
    return (
        <div className={styles.appControlsContainer}>
            <img className={styles.logo} src="/TrexFavicon.svg" />
            <div className={styles.navigationalControlsContainer}>
                <StandardAppControls
                    isMobileView={isMobileView}
                    viewerRole={viewerRole}
                />
            </div>
        </div>
    )
}

interface StandardAppControlsI {
    isMobileView: boolean
    viewerRole: SubjectRole
}

const StandardAppControls: FunctionComponent<StandardAppControlsI> = ({
    isMobileView,
    viewerRole,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    let standardAppControlsComponent: ReactNode
    const controls = getNavigationBarControlsByRole(viewerRole)

    if (isMobileView) {
        const options = controls.map(({ label, value }) => {
            return (
                <Item
                    key={value}
                    onClick={() => {
                        if (value === "dashboard") {
                            navigate("/dashboard")
                        }

                        if (value === "user-admin") {
                            navigate("/admin/users")
                        }

                        if (value === "org-admin") {
                            navigate("/admin/organizations")
                        }

                        if (value === "notifications") {
                            navigate(NOTIFICATIONS_ABS_ROUTE)
                        }
                    }}
                    className={styles.DropdownMenuItem}
                >
                    {label}
                </Item>
            )
        })

        standardAppControlsComponent = (
            <RadixDropDownMenu
                triggerChildren={
                    <FontAwesomeIcon color="#fff" icon={faEllipsis} />
                }
            >
                {options}
            </RadixDropDownMenu>
        )
    } else {
        standardAppControlsComponent = (
            <div className={styles.standardNavigationalControlsContainer}>
                <div className={styles.standardControlItem}>
                    <NavigationControls
                        usage={"nav-bar"}
                        condition={
                            location.pathname.startsWith("/dashboard")
                                ? "selected"
                                : "default"
                        }
                        disabled={false}
                        handleClick={() => {
                            navigate("/dashboard")
                        }}
                    >
                        Well Dashboard
                    </NavigationControls>
                </div>
                {/* When notifications is ready to be deployed to live remove viewrRole === SubjectRole.PpoAdmin */}
                {viewerRole === SubjectRole.PpoAdmin && (
                    <div className={styles.standardControlItem}>
                        <NavigationControls
                            usage={"nav-bar"}
                            condition={
                                location.pathname.startsWith("/notifications")
                                    ? "selected"
                                    : "default"
                            }
                            disabled={false}
                            handleClick={() => {
                                navigate(NOTIFICATIONS_ABS_ROUTE)
                            }}
                        >
                            Notifications
                        </NavigationControls>
                    </div>
                )}
                {canRoleAccessUserAdminPage(viewerRole) && (
                    <div className={styles.standardControlItem}>
                        <NavigationControls
                            usage="nav-bar"
                            condition={
                                location.pathname.startsWith("/admin/users")
                                    ? "selected"
                                    : "default"
                            }
                            disabled={false}
                            handleClick={() => {
                                navigate("/admin/users")
                            }}
                        >
                            User Admin
                        </NavigationControls>
                    </div>
                )}
                {canRoleAccessOrgAdminPage(viewerRole) && (
                    <div className={styles.standardControlItem}>
                        <NavigationControls
                            usage="nav-bar"
                            condition={
                                location.pathname.startsWith(
                                    "/admin/organizations"
                                )
                                    ? "selected"
                                    : "default"
                            }
                            disabled={false}
                            handleClick={() => {
                                navigate("/admin/organizations")
                            }}
                        >
                            Org Admin
                        </NavigationControls>
                    </div>
                )}
            </div>
        )
    }

    return <>{standardAppControlsComponent}</>
}

interface ManagementControlsPropsInterface {
    userInitials?: string
    viewerRole: SubjectRole
}

const ManagementControls: FunctionComponent<
    ManagementControlsPropsInterface
> = ({ userInitials, viewerRole }) => {
    const navigate = useNavigate()
    const auth = useAuth()
    const config = useContext(ConfigContext)
    const accessToInviteUser = canRoleInviteUser(viewerRole)
    // menu state
    const [menuOpen, setMenuOpen] = useState(false)
    const menuRef = useRef<HTMLDivElement>(null)

    // effect hook to add event listner
    useEffect(() => {
        document.addEventListener("click", handleDocumentClick)

        return () => {
            document.removeEventListener("click", handleDocumentClick)
        }
    }, [])

    // handlers for the menu
    const handleDocumentClick = (event: MouseEvent) => {
        if (
            menuRef.current &&
            !menuRef.current.contains(event.target as Node)
        ) {
            setMenuOpen(false)
        }
    }

    const handleDropDownOptionClick = async (
        e: React.MouseEvent<HTMLDivElement>
    ) => {
        if ((e.target as HTMLElement).id === "UserMenuDropDown_logout") {
            setTimeout(() => {
                auth.signoutRedirect({
                    extraQueryParams: {
                        client_id: config.oidcClientID,
                        redirect_uri: config.redirectLoggedInURL,
                        response_type: "code",
                    },
                })
            }, 500)
        } else if (
            (e.target as HTMLElement).id === "UserMenuDropDown_userSettings"
        ) {
            navigate(USER_SETTINGS_ABS_ROUTE)
        }
        setMenuOpen(false)
    }

    const handleDropDownMenuClick = () => {
        setMenuOpen(!menuOpen)
    }

    return (
        <div className={styles.dropDownContainer}>
            {accessToInviteUser && (
                <div className={styles.inviteUserContainer}>
                    <Button
                        handleButtonClick={() => {
                            navigate("inviteUser", { relative: "path" })
                        }}
                        status="secondary"
                        condition="default"
                        disabled={false}
                    >
                        <span style={{ color: "white" }}>Invite User</span>
                    </Button>
                </div>
            )}
            <div className={styles.userIcon}>{userInitials || ""}</div>
            <NavigationControlDropdown
                options={getNavigationMenuOptionsByRole(viewerRole)}
                handleDropDownOptionClick={handleDropDownOptionClick}
                dropDownButtonClass="dropDown-button-class"
                dropDownOptionsContainerClass="dropDown-options-container"
                dropDownOptionClass="dropDown-option"
                positionClass={"navigation"}
                menuOpen={menuOpen}
                handleDropDownMenuClick={handleDropDownMenuClick}
                menuRef={menuRef}
                id="UserMenuDropDown"
            >
                <FontAwesomeIcon icon={faChevronDown} />
            </NavigationControlDropdown>
        </div>
    )
}

export default NavigationBar
