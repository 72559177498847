import { FunctionComponent, ReactNode } from "react"
import styles from "./addRuleModal.module.scss"
import ModalHeader from "../../../../shared/modalHeader"
import { useLocation, useNavigate } from "react-router-dom"
import { TrexNavigator } from "../../../../../classes/navigator/navigator"
import { GET_METRICS } from "../../../../../graphql/queries/metrics"
import { useQuery } from "@apollo/client"
import {
    ErrorMessage,
    SyncLoaderComponent,
} from "../../../../shared/graphQlResponse"
import { getFragmentData } from "../../../../../generated"
import { ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRuleMetricDefinition"
import AddAlertRuleFormContainer from "./formContainer"
import { SubjectRole } from "../../../../../generated/graphql"
import { useViewerContext } from "../../../../../context/ViewerContext"
import { useNotificationsContext } from "../../../context/notificationsContext"

export const AddAlertRule: FunctionComponent = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const viewer = useViewerContext().getViewer()
    const notificationsContext = useNotificationsContext()

    // query
    const { error, loading, data } = useQuery(GET_METRICS, {
        variables: {
            AlertRuleMetricsInput: {
                externalOnly: requestExternalOnly(
                    viewer.role,
                    viewer.currentOrganization.value,
                    notificationsContext.selectedOrganization.value
                ),
            },
        },
    })

    let addAlertRuleFormComponent: ReactNode
    if (loading) {
        addAlertRuleFormComponent = (
            <SyncLoaderComponent outSideDivStyle={styles.queryLoading} />
        )
    } else if (error) {
        addAlertRuleFormComponent = (
            <ErrorMessage
                outSideDivStyle={styles.queryError}
                message="We had a problem while fetching our list of alert rule metrics."
            />
        )
    } else {
        const metricsQueryRsp = data?.metrics.metrics.map((m) => {
            return getFragmentData(
                ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT,
                m
            )
        })
        if (!metricsQueryRsp) {
            return <></>
        }
        addAlertRuleFormComponent = (
            <AddAlertRuleFormContainer metricsQueryRsp={metricsQueryRsp} />
        )
    }

    // The modal footer is in the AddAlertRuleFormContainer component
    // The submit function needs access to the form state to submit the add mutation
    return (
        <div className={styles.container}>
            <ModalHeader
                trexNavigator={
                    new TrexNavigator(
                        {
                            navigateTo: -1,
                            locationKey: location.key,
                            pathName: location.pathname,
                        },
                        navigate
                    )
                }
                title="Add Alert Rule"
            />
            {addAlertRuleFormComponent}
        </div>
    )
}

export default AddAlertRule

const requestExternalOnly = (
    viewerRole: SubjectRole,
    viewerRootOrganization: string,
    selectedOrganization: string
) => {
    // non ppo admin can only see external metrics. This is validated on backend
    if (viewerRole !== SubjectRole.PpoAdmin) {
        return true
    }

    // if ppo admin is viewing a different organization, they should only see external metrics
    if (viewerRootOrganization !== selectedOrganization) {
        return true
    }

    // if ppo admin is viewing their own organization, they should see all metrics
    return false
}
