import {
    FunctionComponent,
    useDeferredValue,
    useEffect,
    useMemo,
    useState,
} from "react"
import { SubscriptionsAdminTableI } from "../../tableTypes"
import { useSearchParams } from "react-router-dom"
import {
    getSubscriptionAdminStateFromUrlParams,
    subscriptionsAdminPageSizeSelector,
    subscriptionsAdminTableUrlMapping,
} from "../../tableUtil"
import {
    ColumnFiltersState,
    SortingState,
    Table,
    flexRender,
    getCoreRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table"
import { METRIC_COLUMN_ID, SUBSCRIBER_COLUMN_ID } from "../../column"
import { tableStateToUrlSearchParams } from "../../../../../table/tableUtil"
import styles from "./table.module.scss"
import classNames from "classnames"
import TanStackPaginator from "../../../../../shared/tanStack/paginator"
import { subscriptionsByUserColumns } from "./column/column"
import SubscriptionsByUserTableHeader from "../header"
import SubscriptionsByUserActionHeader from "../actionHeader"
import { NOTIFICATIONS_SUBSCRIPTION_BY_USER_ABS_ROUTE } from "../../../../../../index"
import { useNotificationsContext } from "../../../../context/notificationsContext"

const SubscriptionsByUserTable: FunctionComponent<SubscriptionsAdminTableI> = ({
    data,
}) => {
    const selectedOrgId = useNotificationsContext().selectedOrganization.value
    // init state from url
    const [searchParams, setSearchParams] = useSearchParams()

    const initUrlState = useMemo(() => {
        return getSubscriptionAdminStateFromUrlParams(
            searchParams,
            subscriptionsAdminTableUrlMapping
        )
    }, [data])

    // table state
    const [globalFilter, setGlobalFilter] = useState<string>(
        initUrlState.search
    )
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
        initUrlState.filter
    )

    const [sortingState, setSortingState] = useState<SortingState>(
        initUrlState.sorting
    )
    const [paginationState, setPaginationState] = useState({
        pageIndex: initUrlState.pageIndex,
        pageSize: initUrlState.size,
    })

    // deferred state
    const deferredGlobalFilter = useDeferredValue(globalFilter)
    const deferredColumnFilters = useDeferredValue(columnFilters)
    const isStateDeferred =
        deferredGlobalFilter !== globalFilter ||
        deferredColumnFilters !== columnFilters

    // table instance
    const table = useReactTable({
        columns: subscriptionsByUserColumns,
        data: data,
        // autoResetPageIndex is because false : if true, the page index will be reset to 0 whenever the data changes (from a mutation).
        // That creates a bad user experience, in this circumstance if the user updates a subscription on page 5 they would be directed back to page 1.
        // When autoResetPageIndex is false, we need to manually reset the page index when updating filters, globals and sorting
        autoResetPageIndex: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getPaginationRowModel: getPaginationRowModel(),
        onGlobalFilterChange: (updater) => {
            table.setPageIndex(0)
            return setGlobalFilter(updater)
        },
        onColumnFiltersChange: (updater) => {
            table.setPageIndex(0)
            return setColumnFilters(updater)
        },
        onPaginationChange: (updater) => {
            return setPaginationState(updater)
        },
        onSortingChange: (updater) => {
            table.setPageIndex(0)
            return setSortingState(updater)
        },
        initialState: {
            columnVisibility: {
                [METRIC_COLUMN_ID]: false,
                [SUBSCRIBER_COLUMN_ID]: false,
            },
        },
        state: {
            globalFilter: deferredGlobalFilter,
            columnFilters: deferredColumnFilters,
            pagination: paginationState,
            sorting: sortingState,
        },
    })

    // Effect hook to keep app state in sync with URL
    // if the dependencies change based on updated table state - this effect will fire and update the URL
    useEffect(() => {
        // do not set parameters if the current url is a modal and not the  subscription by user dashboard
        // This causes issues when navigating directly to a modal
        if (
            !location.pathname.endsWith(
                NOTIFICATIONS_SUBSCRIPTION_BY_USER_ABS_ROUTE
            )
        ) {
            return
        }

        const newParams = tableStateToUrlSearchParams(
            paginationState,
            sortingState,
            columnFilters,
            globalFilter
        )

        setSearchParams(newParams, { replace: true })
        // having data as a dependency allows the resynchronization of app state & url state after mutations
    }, [columnFilters, sortingState, globalFilter, paginationState, data])

    // reset the page index to zero when the selected organization changes.
    useEffect(() => {
        table.setPageIndex(0)
    }, [selectedOrgId])

    return (
        <>
            <div className={styles.tableContainer}>
                <SubscriptionsByUserActionHeader
                    globalFilter={globalFilter}
                    table={table}
                />
                <div className={styles.overflowContainer}>
                    <table className={styles.tableClass}>
                        <SubscriptionsByUserTableHeader table={table} />
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr
                                    className={classNames(
                                        styles.tableRow,
                                        isStateDeferred && styles.deferredRow
                                    )}
                                    key={row.id}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <td
                                            className={classNames(
                                                styles.tableCell,
                                                styles[`${cell.column.id}_Cell`]
                                            )}
                                            key={cell.id}
                                        >
                                            <span className={styles.cellSpan}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </span>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <TanStackPaginator
                    pageSizeOptions={subscriptionsAdminPageSizeSelector}
                    tableInstance={table as Table<unknown>}
                />
            </div>
        </>
    )
}

export default SubscriptionsByUserTable
