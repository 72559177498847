import {
    NOTIFICATIONS_SUBSCRIPTION_BY_RULE_ABS_ROUTE,
    NOTIFICATIONS_SUBSCRIPTION_BY_USER_ABS_ROUTE,
    NOTIFICATIONS_RULES_ABS_ROUTE,
    NOTIFICATIONS_SUBSCRIPTION_ABS_ROUTE,
    NOTIFICATIONS_RULES_UPDATE_RULE_ABS_ROUTE,
    MY_NOTIFICATIONS_ABS_ROUTE,
    NOTIFICATIONS_ADMIN_UPDATE_SUBSCRIPTION_CHILD,
    NOTIFICATIONS_ADMIN_SILENCE_CHILD,
    NOTIFICATIONS_ADMIN_DELETE_SUBSCRIPTION_CHILD,
    NOTIFICATIONS_SUBSCRIPTION_UPDATE_SUBSCRIPTION_CHILD,
    NOTIFICATIONS_SILENCE_CHILD,
    NOTIFICATIONS_DELETE_SUBSCRIPTION_CHILD,
} from "../../.."
import { SubjectRole } from "../../../generated/graphql"
import {
    LabelRouteObjType,
    LabelValueOptionType,
} from "../../../types/commonTypes"
import { RolesUiDisplayObj } from "../../subjectRole"

const getWellConfigurationMenuOptionsByRole = (
    role: SubjectRole,
    isWellLinked: boolean
) => {
    let menuOptions: LabelValueOptionType[]
    switch (role) {
        case SubjectRole.PpoAdmin:
        case SubjectRole.OrgAdmin:
            menuOptions = [
                { label: "Link", value: "link" },
                { label: "Unlink", value: "unlink" },
            ]
            if (isWellLinked) {
                menuOptions.unshift({
                    label: "Well Controls",
                    value: "wellControls",
                })
            }
            break
        case SubjectRole.OrgMember:
            if (isWellLinked) {
                menuOptions = [
                    {
                        label: "Well Controls",
                        value: "wellControls",
                    },
                ]
            } else {
                menuOptions = []
            }
            break
        default:
            menuOptions = []
    }
    return menuOptions
}

const getUpdateUserRoleOptionsByRole = (role: SubjectRole) => {
    let menuOptions: LabelValueOptionType[]

    switch (role) {
        case SubjectRole.PpoAdmin:
            menuOptions = [
                {
                    label: RolesUiDisplayObj[SubjectRole.PpoAdmin],
                    value: SubjectRole.PpoAdmin,
                },
                {
                    label: RolesUiDisplayObj[SubjectRole.OrgAdmin],
                    value: SubjectRole.OrgAdmin,
                },
                {
                    label: RolesUiDisplayObj[SubjectRole.OrgMember],
                    value: SubjectRole.OrgMember,
                },
            ]
            break
        case SubjectRole.OrgAdmin:
            menuOptions = [
                {
                    label: RolesUiDisplayObj[SubjectRole.OrgAdmin],
                    value: SubjectRole.OrgAdmin,
                },
                {
                    label: RolesUiDisplayObj[SubjectRole.OrgMember],
                    value: SubjectRole.OrgMember,
                },
            ]
            break
        case SubjectRole.OrgMember:
            menuOptions = []
            break
        default:
            menuOptions = []
    }
    return menuOptions
}

const getNavigationBarControlsByRole = (role: SubjectRole) => {
    let navOptions: LabelValueOptionType[]
    switch (role) {
        case SubjectRole.PpoAdmin:
            navOptions = [
                { label: "Well Dashboard", value: "dashboard" },
                { label: "Notifications", value: "notifications" },
                { label: "User Admin", value: "user-admin" },
                { label: "Org Admin", value: "org-admin" },
            ]
            break
        case SubjectRole.OrgAdmin:
            navOptions = [
                { label: "Well Dashboard", value: "dashboard" },
                { label: "User Admin", value: "user-admin" },
            ]
            break
        case SubjectRole.OrgMember:
            navOptions = [{ label: "Well Dashboard", value: "dashboard" }]
            break
        default:
            navOptions = [{ label: "Well Dashboard", value: "dashboard" }]
    }
    return navOptions
}

const getNavigationMenuOptionsByRole = (role: SubjectRole) => {
    let menuOptions: LabelValueOptionType[]
    // Options are same for all roles
    switch (role) {
        case SubjectRole.PpoAdmin:
        case SubjectRole.OrgAdmin:
        case SubjectRole.OrgMember:
            menuOptions = [
                { label: "User Settings", value: "userSettings" },
                { label: "Logout", value: "logout" },
            ]
            break
        default:
            menuOptions = [{ label: "Logout", value: "logout" }]
    }
    return menuOptions
}

const getAlertRuleMenuByRole = (
    role: SubjectRole,
    ruleId: string
): LabelRouteObjType[] => {
    let menuOptions: LabelRouteObjType[]
    switch (role) {
        case SubjectRole.PpoAdmin:
        case SubjectRole.OrgAdmin:
            menuOptions = [
                // more options to be added as notifications service is completed. // subscriptions
                {
                    label: "Update Rule",
                    route: NOTIFICATIONS_RULES_UPDATE_RULE_ABS_ROUTE(ruleId),
                },
            ]

            break
        case SubjectRole.OrgMember:
        default:
            menuOptions = []
    }

    return menuOptions
}

const getNotificationsSubNavigationByRole = (role: SubjectRole) => {
    const menuOptions: { label: string; route: string }[] = [
        { label: "Notifications Inbox", route: MY_NOTIFICATIONS_ABS_ROUTE },
        { label: "Alert Rules", route: NOTIFICATIONS_RULES_ABS_ROUTE },
        {
            label: "My Subscriptions",
            route: NOTIFICATIONS_SUBSCRIPTION_ABS_ROUTE,
        },
    ]

    switch (role) {
        case SubjectRole.PpoAdmin:
        case SubjectRole.OrgAdmin:
            menuOptions.push(
                {
                    label: "Subscriptions By Rule",
                    route: NOTIFICATIONS_SUBSCRIPTION_BY_RULE_ABS_ROUTE,
                },
                {
                    label: "Subscriptions By User",
                    route: NOTIFICATIONS_SUBSCRIPTION_BY_USER_ABS_ROUTE,
                }
            )
            break
    }

    return menuOptions
}

const getMySubscriptionsMenuByRole = (
    role: SubjectRole,
    subscriptionId: string
) => {
    const menuOptions: { label: string; route: string }[] = [
        {
            label: "Update Subscription",
            route: `${subscriptionId}/${NOTIFICATIONS_SUBSCRIPTION_UPDATE_SUBSCRIPTION_CHILD}`,
        },
        {
            label: "Manage Silence",
            route: `${subscriptionId}/${NOTIFICATIONS_SILENCE_CHILD}`,
        },
        {
            label: "Delete Subscription",
            route: `${subscriptionId}/${NOTIFICATIONS_DELETE_SUBSCRIPTION_CHILD}`,
        },
    ]

    switch (role) {
        case SubjectRole.PpoAdmin:
        case SubjectRole.OrgAdmin:
        case SubjectRole.OrgMember:
            return menuOptions
    }

    return []
}

const getMyNotificationsMenuByRole = (
    role: SubjectRole,
    subscriptionId: string
) => {
    const menuOptions: { label: string; route: string }[] = [
        {
            label: "Update Subscription",
            route: `${subscriptionId}/${NOTIFICATIONS_SUBSCRIPTION_UPDATE_SUBSCRIPTION_CHILD}`,
        },
        {
            label: "Manage Silence",
            route: `${subscriptionId}/${NOTIFICATIONS_SILENCE_CHILD}`,
        },
        {
            label: "Delete Subscription",
            route: `${subscriptionId}/${NOTIFICATIONS_DELETE_SUBSCRIPTION_CHILD}`,
        },
    ]

    switch (role) {
        case SubjectRole.PpoAdmin:
        case SubjectRole.OrgAdmin:
        case SubjectRole.OrgMember:
            return menuOptions
    }

    return []
}

const getAdminSubscriptionsMenuByRole = (
    role: SubjectRole,
    subscriptionId: string
) => {
    const menuOptions: { label: string; route: string }[] = [
        {
            label: "Update Subscription",
            route: `${subscriptionId}/${NOTIFICATIONS_ADMIN_UPDATE_SUBSCRIPTION_CHILD}`,
        },
        {
            label: "Manage Silence",
            route: `${subscriptionId}/${NOTIFICATIONS_ADMIN_SILENCE_CHILD}`,
        },
        {
            label: "Delete Subscription",
            route: `${subscriptionId}/${NOTIFICATIONS_ADMIN_DELETE_SUBSCRIPTION_CHILD}`,
        },
    ]

    switch (role) {
        case SubjectRole.PpoAdmin:
        case SubjectRole.OrgAdmin:
            return menuOptions
        case SubjectRole.OrgMember:
            return []
    }

    return []
}

export {
    getWellConfigurationMenuOptionsByRole,
    getUpdateUserRoleOptionsByRole,
    getNavigationMenuOptionsByRole,
    getAlertRuleMenuByRole,
    getNavigationBarControlsByRole,
    getNotificationsSubNavigationByRole,
    getMySubscriptionsMenuByRole,
    getMyNotificationsMenuByRole,
    getAdminSubscriptionsMenuByRole,
}
