import { FunctionComponent, ReactNode } from "react"
import * as Tooltip from "@radix-ui/react-tooltip"
import styles from "./radixTooltip.module.scss"

interface RadixToolTipI {
    triggerChildren: ReactNode
    tooltipContent: ReactNode
    side?: "top" | "right" | "bottom" | "left"
    renderDelay?: number
}

const RadixToolTip: FunctionComponent<RadixToolTipI> = ({
    triggerChildren,
    tooltipContent,
    side = "top",
    renderDelay = 400,
}) => {
    return (
        <>
            <Tooltip.Provider delayDuration={renderDelay}>
                <Tooltip.Root>
                    <Tooltip.Trigger asChild>{triggerChildren}</Tooltip.Trigger>
                    <Tooltip.Portal>
                        <Tooltip.Content
                            className={styles.tooltipContent}
                            sideOffset={5}
                            side={side}
                        >
                            {tooltipContent}
                            <Tooltip.Arrow className={styles.tooltipArrow} />
                        </Tooltip.Content>
                    </Tooltip.Portal>
                </Tooltip.Root>
            </Tooltip.Provider>
        </>
    )
}

export default RadixToolTip
