import React, { FunctionComponent, useState } from "react"
import Button from "../../components/shared/button"
import styles from "./userProfileSettings.module.scss"
import { SEND_CODE } from "../../graphql/mutations/verify"
import { useMutation } from "@apollo/client"
import { AttributeType } from "../../generated/graphql"
import { createSearchParams, useNavigate } from "react-router-dom"
import { USER_SETTINGS_VERIFY_ABS_ROUTE } from "../.."
import MutationErrorBanner from "../../components/shared/graphQlResponse"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faXmark } from "@fortawesome/pro-solid-svg-icons"

interface UserContactVerificationComponent {
    sectionTitle: string
    value: string | null | undefined
    verified: boolean
    separator?: boolean
    attr: "email" | "sms"
    disclaimerText?: string
    onEditClick: () => void
}

const getEditButtonText = (attr: "email" | "sms", empty: boolean) => {
    switch (attr) {
        case "email":
            return empty ? "Add Email" : "Edit Email"
        case "sms":
            return empty ? "Add Phone" : "Edit Phone"
        default:
            return "Edit"
    }
}

const getValueText = (
    attr: "email" | "sms",
    value: string | null | undefined
) => {
    switch (attr) {
        case "email":
            return value
                ? value
                : "Add your email address to enable email alert notifications from T-REX."
        case "sms":
            return value
                ? value
                : "Add your phone number to enable text alert notifications from T-REX."
        default:
            return ""
    }
}

export const UserContactVerificationSection: FunctionComponent<
    UserContactVerificationComponent
> = ({ sectionTitle, value, onEditClick, attr, disclaimerText, verified }) => {
    const navigate = useNavigate()
    const [sendCodeMutation, { loading: mutationLoading }] =
        useMutation(SEND_CODE)

    const [mutationErrorBanner, setMutationErrorBanner] = useState<
        string | undefined
    >(undefined)

    const handleVerifyClick = async (attr: "email" | "sms") => {
        let attrType: AttributeType
        switch (attr) {
            case "email":
                attrType = AttributeType.Email
                break
            case "sms":
                attrType = AttributeType.Sms
                break
            default:
                setMutationErrorBanner("Invalid attribute type")
                return
        }

        try {
            await sendCodeMutation({
                variables: {
                    SendVerifyCodeInput: {
                        type: attrType,
                    },
                },
            })
            navigate({
                pathname: USER_SETTINGS_VERIFY_ABS_ROUTE,
                search: createSearchParams({ attr: attr }).toString(),
            })
        } catch (error) {
            console.error("Error sending code: ", error)
            setMutationErrorBanner("Error sending code: " + error)
        }
    }

    const renderVerifyButton = () => {
        if (verified || !value) {
            return null
        } else {
            return (
                <Button
                    status="secondary"
                    handleButtonClick={() => handleVerifyClick(attr)}
                    disabled={mutationLoading}
                >
                    Verify
                </Button>
            )
        }
    }

    const renderDisclaimer = () => {
        if (disclaimerText && value) {
            return (
                <>
                    <div className={styles.separator} />
                    <div
                        style={{
                            display: "flex",
                            alignContent: "flex-start",
                            marginLeft: "16px",
                            marginBottom: "16px",
                        }}
                    >
                        <div className={styles.disclaimerText}>
                            {disclaimerText}
                        </div>
                    </div>
                </>
            )
        }

        return null
    }

    return (
        <>
            <UserProfileSectionTitleRow title={sectionTitle}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Button
                        status="primary"
                        handleButtonClick={onEditClick}
                        condition="default"
                    >
                        {getEditButtonText(attr, !value)}
                    </Button>
                    {renderVerifyButton()}
                </div>
            </UserProfileSectionTitleRow>
            <UserProfileInfoRow direction="column">
                <UserProfileInfoContainer
                    heading={
                        attr === "email" ? "Email Address" : "Phone Number"
                    }
                    value={getValueText(attr, value)}
                    direction="row"
                />
                <div className={styles.separator} />
                <UserProfileInfoContainer
                    heading="Verified"
                    value={verified}
                    direction="row"
                />
                {renderDisclaimer()}
            </UserProfileInfoRow>

            {mutationErrorBanner && (
                <MutationErrorBanner
                    message={
                        <div>
                            There was a problem updating your notification
                            preferences: {mutationErrorBanner}
                        </div>
                    }
                />
            )}
        </>
    )
}

interface UserProfileInfoContainerComponent {
    heading: string
    value: string | number | boolean | null | undefined
    direction?: "row" | "column"
}

export const UserProfileInfoContainer: FunctionComponent<
    UserProfileInfoContainerComponent
> = ({ heading, value, direction = "column" }) => {
    const classes = classNames(styles.userProfileInfoContainer, {
        [styles.userProfileInfoContainerRow]: direction === "row",
        [styles.userProfileInfoContainerColumn]: direction === "column",
    })

    const renderValue = () => {
        if (value === null || value === undefined) {
            return "N/A"
        }

        if (typeof value === "boolean") {
            return value ? (
                <FontAwesomeIcon icon={faCheck} color="green" />
            ) : (
                <FontAwesomeIcon icon={faXmark} color="red" />
            )
        }

        return <div className={styles.userProfileInfoValue}>{value}</div>
    }

    return (
        <div className={classes}>
            <div className={styles.userProfileInfoHeading}>{heading}</div>
            {renderValue()}
        </div>
    )
}

export const UserProfileInfoRow: FunctionComponent<{
    children: React.ReactNode
    direction?: "row" | "column"
}> = ({ children, direction }) => {
    const classes = classNames(styles.userProfileInfo, {
        [styles.userProfileInfoRow]: direction === "row" || !direction,
        [styles.userProfileInfoColumn]: direction === "column",
    })

    return <div className={classes}>{children}</div>
}

export const UserProfileNotificationContainer: FunctionComponent<{
    children: React.ReactNode
}> = ({ children }) => {
    return <div className={styles.notificationContainer}>{children}</div>
}

export const UserProfileSectionTitleRow: FunctionComponent<{
    title: string
    children?: React.ReactNode
    direction?: "row" | "column"
}> = ({ title, children, direction }) => {
    const classes = classNames(styles.sectionTitle, {
        [styles.sectionTitleRow]: direction === "row" || !direction,
        [styles.sectionTitleColumn]: direction === "column",
    })

    return (
        <div className={classes}>
            <span>{title}</span>
            {children}
        </div>
    )
}

interface UserProfileInfoRowActionsComponent {
    actions: [
        {
            label: string
            status: "primary" | "secondary"
            onClick: () => void
        }
    ]
}

export const UserProfileInfoRowActions: FunctionComponent<
    UserProfileInfoRowActionsComponent
> = ({ actions }) => {
    return (
        <div className={styles.userProfileInfoRowActions}>
            {actions.map((action, index) => (
                <Button
                    key={index}
                    status={action.status}
                    handleButtonClick={action.onClick}
                >
                    {action.label}
                </Button>
            ))}
        </div>
    )
}
