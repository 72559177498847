import { graphql } from "../../generated"

export const SUBJECT_FIELDS_FRAGMENT = graphql(`
    fragment SubjectFields on Subject {
        id
        role
        identity {
            ...IdentityFields
        }
    }
`)
