import { FunctionComponent } from "react"
import { useNavigate } from "react-router-dom"
import { TrexNavigator } from "../../../classes/navigator/navigator"
import ModalHeader from "../../shared/modalHeader"
import { USER_SETTINGS_ABS_ROUTE } from "../../.."
import UpdatePhoneNumberFormComponent from "./form"
import { useViewerContext } from "../../../context/ViewerContext"

const UpdateProfilePhoneNumber: FunctionComponent = () => {
    const navigate = useNavigate()
    const { getViewer } = useViewerContext()
    const { userId, identity } = getViewer()

    return (
        <>
            <ModalHeader
                title={"Add Phone Number for Notifications"}
                trexNavigator={
                    new TrexNavigator(
                        { navigateTo: USER_SETTINGS_ABS_ROUTE },
                        navigate
                    )
                }
            />
            <UpdatePhoneNumberFormComponent
                userId={userId}
                phoneNumber={identity.phoneNumber || ""}
            />
        </>
    )
}

export default UpdateProfilePhoneNumber
