import { FunctionComponent, useMemo, useState } from "react"
import ModalFooter from "../../../../shared/modalFooter"
import { useNavigate, useParams } from "react-router-dom"
import {
    AlertRuleFormConditionI,
    AlertRuleFormErrorI,
    AlertRuleFormI,
} from "../alertRuleFormUtil/alertRuleFormTypes"
import ManageAlertRuleForm, {
    CONDITION_THRESHOLD_2_INPUT_NAME,
    CONDITION_THRESHOLD_INPUT_NAME,
} from "../form"
import { useMutation } from "@apollo/client"
import { getFragmentData } from "../../../../../generated"
import { AlertRuleFieldsFragment, Unit } from "../../../../../generated/graphql"
import { DURATION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/duration"
import { findIndexByKeyInArray } from "../../../../../util/arrays/array"
import { ALERT_TEMPLATE_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRuleTemplate"
import { DurationUnitT } from "../../../../../util/duration/types"
import {
    getErrorsFromAlertRuleForm,
    isAlertRuleFormDisabled,
} from "../alertRuleFormUtil/validation"
import { getAlertRuleFormFromAlertRuleFragment } from "../alerts_GraphQL/alertRuleFragment"
import { getAlertRuleFormFromTemplate } from "../alerts_GraphQL/alertRuleTemplateFragment"
import { getMetricOptionsFromMetrics } from "../alerts_GraphQL/alertRuleMetricFragment"
import { getUpdateAlertRuleMutationVars } from "../alertRuleFormUtil/api"
import { NOTIFICATIONS_RULES_ABS_ROUTE } from "../../../../.."
import { ALERT_RULE_UPDATE } from "../../../../../graphql/mutations/alertRule_update"
import AlertRuleFormActions from "../actions"
import MutationErrorBanner from "../../../../shared/graphQlResponse"
import {
    parseInputToFloat,
    parseNumberInput,
} from "../../../../../util/InputValidation/inputValidation"
import { ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT } from "../../../../../graphql/fragments/alertRuleMetricDefinition"

interface UpdateAlertRuleFormContainerI {
    ruleToBeUpdated: AlertRuleFieldsFragment
}

const UpdateAlertRuleFormContainer: FunctionComponent<
    UpdateAlertRuleFormContainerI
> = ({ ruleToBeUpdated }) => {
    const navigate = useNavigate()
    const { ruleId: ruleIdFromUrl } = useParams()

    // mutation
    const [updateAlertRuleMutation, { loading: mutationLoading }] =
        useMutation(ALERT_RULE_UPDATE)

    // get inital form state from rule
    const initialFormState =
        getAlertRuleFormFromAlertRuleFragment(ruleToBeUpdated)

    // form state
    const [updateAlertRuleForm, setUpateAlertRuleForm] =
        useState<AlertRuleFormI>(initialFormState)

    const [updateAlertRuleFormError, setUpdateAlertRuleFormError] =
        useState<AlertRuleFormErrorI>({
            ruleName: undefined,
            threshold: undefined,
            threshold2: undefined,
        })

    const [mutationErrorBanner, setMutationErrorBanner] =
        useState<boolean>(false)

    // form handlers
    const handleWindowChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        // target value
        const totalSeconds = e.target.value

        // get alert rule metric from rule to be updated
        const alertRuleMetric = getFragmentData(
            ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT,
            ruleToBeUpdated.metric
        )

        // map  template fields fragment into array
        const templates = alertRuleMetric.templates?.map((t) => {
            return getFragmentData(ALERT_TEMPLATE_FIELDS_FRAGMENT, t)
        })

        if (!templates) {
            return
        }

        // find index of newly selected window in templates
        const i = findIndexByKeyInArray(
            templates,
            (obj) =>
                getFragmentData(
                    DURATION_FIELDS_FRAGMENT,
                    obj.window
                ).totalSeconds.toString(),
            totalSeconds
        )

        if (typeof i !== "number") {
            return
        }

        const updatedTemplate = templates[i]

        // update form && clear errors
        // because the user is setting the form with a new template, the errors can be cleared.
        const updatedForm = getAlertRuleFormFromTemplate(updatedTemplate)
        setUpateAlertRuleForm(updatedForm)
        setUpdateAlertRuleFormError({
            ruleName: undefined,
            threshold: undefined,
            threshold2: undefined,
        })
    }

    const handleRuleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // if there is a form error and the user is updating a rule name input, we can clear it on valid input instead of waiting for blur
        // if there is no current error we should wait for blur
        // this creates a better user experience
        const updatedForm = {
            ...updateAlertRuleForm,
            ruleName: e.target.value,
        }

        let updatedErrForm = { ...updateAlertRuleFormError }

        if (updatedErrForm.ruleName) {
            updatedErrForm = getErrorsFromAlertRuleForm(updatedForm)
        }

        setUpateAlertRuleForm(updatedForm)
        setUpdateAlertRuleFormError(updatedErrForm)
    }

    const handleConditionUnitChange = (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        if (updateAlertRuleForm.condition.unit !== Unit.Duration) {
            return
        }

        const targetUnit = e.target.value as DurationUnitT
        // updatedCondition
        const updatedCondition: AlertRuleFormConditionI = {
            ...updateAlertRuleForm.condition,
            thresholdTimeUnit: targetUnit,
        }
        // updateRuleForm
        const updatedUpdateRuleForm = {
            ...updateAlertRuleForm,
            condition: updatedCondition,
        }

        // if there is a form error and the user is updating a threshold input, we can clear it on valid input instead of waiting for blur
        // if there is no current  error we should wait for blur
        // this creates a better user experience
        let updatedUpdateRuleError = { ...updateAlertRuleFormError }

        if (
            updatedUpdateRuleError.threshold ||
            updatedUpdateRuleError.threshold2
        ) {
            updatedUpdateRuleError = getErrorsFromAlertRuleForm(
                updatedUpdateRuleForm
            )
        }

        // set state for updateAlertRuleForm && updateRuleFormError
        setUpateAlertRuleForm({
            ...updateAlertRuleForm,
            condition: updatedCondition,
        })
        setUpdateAlertRuleFormError(updatedUpdateRuleError)
    }

    const handleConditionThresholdChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        // parse input to float or integer
        let targetValue: number | string
        switch (updateAlertRuleForm.condition.unit) {
            case Unit.Voltage:
                targetValue = parseInputToFloat(e)
                break
            case Unit.Count:
            case Unit.CountPerSecond:
            case Unit.Duration:
            case Unit.Percentage:
            case Unit.StrokesPerMinute:
            default:
                targetValue = parseNumberInput(e)
        }

        const updatedCondition: AlertRuleFormConditionI = {
            ...updateAlertRuleForm.condition,
        }

        if (e.target.name === CONDITION_THRESHOLD_INPUT_NAME) {
            updatedCondition.threshold = targetValue as number
        }
        if (e.target.name === CONDITION_THRESHOLD_2_INPUT_NAME) {
            updatedCondition.threshold2 = targetValue as number
        }

        const updatedUpdateRuleForm = {
            ...updateAlertRuleForm,
            condition: updatedCondition,
        }

        // if there is a form error and the user is updating a threshold input, we can clear it on valid input instead of waiting for blur
        // if there is no current  error we should wait for blur
        // this creates a better user experience
        let updatedUpdateRuleError = { ...updateAlertRuleFormError }

        if (
            updatedUpdateRuleError.threshold ||
            updatedUpdateRuleError.threshold2
        ) {
            updatedUpdateRuleError = getErrorsFromAlertRuleForm(
                updatedUpdateRuleForm
            )
        }

        //  set state for updateAlertRuleForm && updateAlertRuleFormError
        setUpateAlertRuleForm({
            ...updateAlertRuleForm,
            condition: updatedCondition,
        })
        setUpdateAlertRuleFormError(updatedUpdateRuleError)
    }

    const handleDescriptionChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setUpateAlertRuleForm({
            ...updateAlertRuleForm,
            description: e.target.value,
        })
    }

    // blur handlers
    const handleBlurFormInput = (form: AlertRuleFormI) => {
        const updatedAddRuleErr = getErrorsFromAlertRuleForm(form)
        setUpdateAlertRuleFormError(updatedAddRuleErr)
    }

    // submission handler
    const handleSubmitUpdateAlertRule = () => {
        // can form be submitted
        const isDisabled = isAlertRuleFormDisabled(updateAlertRuleForm)

        if (isDisabled || !ruleIdFromUrl) {
            return
        }

        const mutationVariables = getUpdateAlertRuleMutationVars(
            updateAlertRuleForm,
            ruleIdFromUrl
        )

        updateAlertRuleMutation({
            variables: {
                input: mutationVariables,
            },
        })
            .then(() => {
                // Upon successful mutation. Navigate to rules table
                navigate(NOTIFICATIONS_RULES_ABS_ROUTE)
            })
            .catch((error) => {
                console.error("update alert rule mutation error:", error)
                setMutationErrorBanner(true)
            })
    }

    // constants
    // we can use alertRuleMetric on the ruleToBeUpdated to get metric options
    // the  metric input  will be disabled in the form. Metrics can not be updated in the update alert rule modal/form.
    // the input will only have one disabled option
    const metricOptions = useMemo(() => {
        const alertRuleMetric = getFragmentData(
            ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT,
            ruleToBeUpdated.metric
        )
        return getMetricOptionsFromMetrics([alertRuleMetric])
    }, [ruleToBeUpdated])

    const alertRuleMetric = useMemo(() => {
        return getFragmentData(
            ALERT_RULE_METRIC_DEFINITION_FIELDS_FRAGMENT,
            ruleToBeUpdated.metric
        )
    }, [ruleToBeUpdated])

    const formAction = "UPDATE"

    return (
        <>
            <ManageAlertRuleForm
                formAction={formAction}
                form={updateAlertRuleForm}
                metricOptions={metricOptions}
                alertRuleMetric={alertRuleMetric}
                handleRuleNameChange={handleRuleNameChange}
                handleDescriptionChange={handleDescriptionChange}
                handleConditionUnitChange={handleConditionUnitChange}
                handleWindowChange={handleWindowChange}
                handleConditionThresholdChange={handleConditionThresholdChange}
                formError={updateAlertRuleFormError}
                handleBlurFormInput={handleBlurFormInput}
            />
            {!mutationErrorBanner && (
                <>
                    <AlertRuleFormActions
                        ruleForm={updateAlertRuleForm}
                        formAction={formAction}
                    />
                </>
            )}
            {mutationErrorBanner && (
                <>
                    <MutationErrorBanner
                        message={
                            <div>
                                There was problem updating your alert rule.
                                Click{" "}
                                <u
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                        navigate(NOTIFICATIONS_RULES_ABS_ROUTE)
                                    }
                                >
                                    here
                                </u>{" "}
                                or close the modal to be redirected.
                            </div>
                        }
                    />
                </>
            )}
            <ModalFooter
                advanceText="Submit"
                handleAdvanceClick={handleSubmitUpdateAlertRule}
                disableAdvance={
                    isAlertRuleFormDisabled(updateAlertRuleForm) ||
                    mutationLoading ||
                    mutationErrorBanner
                }
            />
        </>
    )
}

export default UpdateAlertRuleFormContainer
