import { TableStateType } from "../../../table/tableTypes"
import {
    METRIC_COLUMN_ID,
    SUBSCRIBED_WELLS_COLUMN_ID,
    SUBSCRIBER_COLUMN_ID,
    RULE_NAME_COLUMN_ID,
} from "./column"

export interface SubscriptionsAdminTableI {
    data: SubscriptionsAdminTableDataI[]
}

export type SubscriptionsAdminTableDataI = {
    id: string
    subscriber: string
    metric: string
    email: boolean
    phone: boolean
    ruleName: string

    // The query result that populates subscribedWells, will be an array of wells. Map each name into a string separated by @SubscriptionsAdminWellNameSep (a pipe "|"). Pipe is used as a separator because it is not likely used in well names.
    // This will allow the global filter to search for a well name in the subscribedWells column.
    subscribedWells: string
}

export const SubscriptionsAdminWellNameSep = "|"

export type SubscriptionsAdminCustomUrlParamsT = {
    [SUBSCRIBED_WELLS_COLUMN_ID]: ""
    [METRIC_COLUMN_ID]: ""
    [SUBSCRIBER_COLUMN_ID]: ""
    [RULE_NAME_COLUMN_ID]: ""
}

export type SubscriptionsAdminUrlToStateMappings = {
    [k in keyof SubscriptionsAdminCustomUrlParamsT]: (
        value: string,
        state: TableStateType
    ) => void
}
