import React, {
    useState,
    FunctionComponent,
    useEffect,
    useRef,
    useLayoutEffect,
} from "react"
import classNames from "classnames"
import { createPortal } from "react-dom"
import styles from "./toolTip.module.scss"
import { debounce } from "../../../util/debounce"

const portalTarget = document.createElement("div")
document.body.appendChild(portalTarget)

interface PortalPropsI {
    children: React.ReactNode
}

interface ToolTipPropsI {
    children: React.ReactNode
    toolTipChildren: React.ReactNode
    orientation: "top" | "left" | "right" | "bottom" | "topLeft"
}

interface ToolTipCordsI {
    left: number | undefined
    top: number | undefined
}

const Portal: FunctionComponent<PortalPropsI> = ({ children }) => {
    return createPortal(children, portalTarget)
}

const ToolTip: FunctionComponent<ToolTipPropsI> = ({
    children,
    toolTipChildren,
    orientation,
}) => {
    const [targetCoords, setTargetCoords] = useState<ToolTipCordsI>({
        left: undefined,
        top: undefined,
    })

    const [centeredToolTipCoords, setCenteredToolTipCoords] =
        useState<ToolTipCordsI>({
            left: undefined,
            top: undefined,
        })

    const [showTooltip, setShowTooltip] = useState(false)
    const tooltipRef = useRef<HTMLDivElement | null>(null)

    /** Handlers */
    const mouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const rect = (e.currentTarget as HTMLDivElement).getBoundingClientRect()
        let updatedCoordinates = {} as ToolTipCordsI

        const pixelsAwayFromAnchor = 20
        const rectCenterY = Math.round((rect.top + rect.bottom) / 2)
        const rectCenterX = Math.round((rect.right + rect.left) / 2)

        switch (orientation) {
            case "right":
                updatedCoordinates = {
                    left: rect.right + pixelsAwayFromAnchor,
                    top: rectCenterY,
                }
                break
            case "left":
                updatedCoordinates = {
                    left: rect.left - pixelsAwayFromAnchor,
                    top: rectCenterY,
                }
                break
            case "top":
                updatedCoordinates = {
                    left: rectCenterX,
                    top: rect.top - pixelsAwayFromAnchor,
                }
                break
            case "topLeft":
                updatedCoordinates = {
                    left: rect.left,
                    top: rect.top - pixelsAwayFromAnchor,
                }
                break
            case "bottom":
                updatedCoordinates = {
                    left: rectCenterX,
                    top: rect.bottom + pixelsAwayFromAnchor,
                }
                break
            default:
                throw new Error(
                    "Shared tool tip component. Orientation should not be null "
                )
        }

        setTargetCoords(() => updatedCoordinates)
        setShowTooltip(true)
    }

    const mouseLeave = () => {
        setShowTooltip(false)
    }

    /** Add event listener to close tooltip when user scrolls */
    useEffect(() => {
        const closeToolTip = () => {
            setShowTooltip(false)
        }

        //  Add event listener to close tooltip when the document gets a scroll event.
        //  This is necessary for mobile.
        const debounceCloseToolTip = debounce(closeToolTip, 500)
        document.addEventListener("scroll", debounceCloseToolTip, true)

        // Cleanup function
        return () => {
            document.removeEventListener("scroll", debounceCloseToolTip, true)
        }
    }, [])

    useLayoutEffect(() => {
        if (!tooltipRef.current) return
        if (!targetCoords.top || !targetCoords.left) return

        switch (orientation) {
            case "right":
                setCenteredToolTipCoords({
                    ...targetCoords,
                    top: targetCoords.top - tooltipRef.current.offsetHeight / 2,
                })
                break
            case "left":
                setCenteredToolTipCoords({
                    ...targetCoords,
                    top: targetCoords.top - tooltipRef.current.offsetHeight / 2,
                    left: targetCoords.left - tooltipRef.current.offsetWidth,
                })
                break
            case "top":
                setCenteredToolTipCoords({
                    ...targetCoords,
                    top: targetCoords.top - tooltipRef.current.offsetHeight,
                    left:
                        targetCoords.left - tooltipRef.current.offsetWidth / 2,
                })
                break
            case "topLeft":
                setCenteredToolTipCoords({
                    ...targetCoords,
                    top: targetCoords.top - tooltipRef.current.offsetHeight,
                    left:
                        targetCoords.left - tooltipRef.current.offsetWidth / 2,
                })
                break
            case "bottom":
                setCenteredToolTipCoords({
                    ...targetCoords,
                    left:
                        targetCoords.left - tooltipRef.current.offsetWidth / 2,
                })
        }
    }, [targetCoords])

    return (
        <div
            style={{ position: "relative" }}
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
        >
            {children}
            {showTooltip && (
                <Portal>
                    <div
                        style={{
                            left: centeredToolTipCoords.left,
                            top: centeredToolTipCoords.top,
                        }}
                        className={classNames(
                            styles.toolTipClass,
                            styles[orientation]
                        )}
                        ref={tooltipRef}
                    >
                        {toolTipChildren}
                    </div>
                </Portal>
            )}
        </div>
    )
}

export default ToolTip
