import { FunctionComponent, useContext, useState } from "react"
import styles from "./userProfileSettings.module.scss"
import { Outlet, useNavigate } from "react-router-dom"
import {
    UserContactVerificationSection,
    UserProfileInfoContainer,
    UserProfileInfoRow,
    UserProfileNotificationContainer,
    UserProfileSectionTitleRow,
} from "./settingsSection"
import { useMutation } from "@apollo/client"
import { ConfigContext } from "../../context/ConfigContexts"
import { useViewerContext } from "../../context/ViewerContext"
import { UPDATE_CONSENT } from "../../graphql/mutations/verify"
import { AttributeType, SubjectRole } from "../../generated/graphql"
import { RolesUiDisplayObj } from "../../util/subjectRole"
import Button from "../../components/shared/button"
import {
    USER_SETTINGS_EDIT_DND_ABS_ROUTE,
    USER_SETTINGS_EDIT_EMAIL_ABS_ROUTE,
    USER_SETTINGS_EDIT_PHONE_NUMBER_ABS_ROUTE,
    USER_SETTINGS_EDIT_PROFILE_ABS_ROUTE,
    USER_SETTINGS_EDIT_TIMEZONE_ABS_ROUTE,
} from "../.."
import { Checkbox } from "../../components/shared/selectionControls"
import { getDisplayStringFromZoneInfo } from "../../graphql/enum/zoneInfo"

const UserProfileSettings: FunctionComponent = () => {
    const navigate = useNavigate()
    const config = useContext(ConfigContext)
    const { getViewer } = useViewerContext()

    const { identity, role, emailConsent, smsConsent } = getViewer()

    if (!identity) {
        throw new Error(" Identity was undefined in  user profile settings")
    }

    const [updateConsentMutation, { loading: updateConsentMutationLoading }] =
        useMutation(UPDATE_CONSENT)

    const [form, setForm] = useState({
        emailVerified: identity.emailVerified,
        emailConsent: emailConsent,
        smsConsent: smsConsent,
        smsVerified: identity.phoneNumberVerified,
    })

    let roleDisplay: string

    switch (role) {
        case SubjectRole.OrgAdmin:
            roleDisplay = RolesUiDisplayObj[SubjectRole.OrgAdmin]
            break
        case SubjectRole.PpoAdmin:
            roleDisplay = RolesUiDisplayObj[SubjectRole.PpoAdmin]
            break
        case SubjectRole.OrgMember:
            roleDisplay = RolesUiDisplayObj[SubjectRole.OrgMember]
            break
        case SubjectRole.Unknown:
            roleDisplay = RolesUiDisplayObj[SubjectRole.Unknown]
            break
        case SubjectRole.System:
            throw new Error(
                "pages/userProfileSettings - System should not be a user role"
            )
        case undefined:
            throw new Error("User profile settings page. Role was undefined")
    }

    const handleForgotPassword = () => {
        window.open(config.forgotPasswordURL, "_blank", "noreferrer")
    }

    const handleAllowNotificationsClick = async (type: "email" | "sms") => {
        let attr: AttributeType
        let consent: boolean
        switch (type) {
            case "email":
                attr = AttributeType.Email
                consent = !form.emailConsent
                setForm({ ...form, emailConsent: consent })
                break
            case "sms":
                attr = AttributeType.Sms
                consent = !form.smsConsent
                setForm({ ...form, smsConsent: consent })
                break
            default:
                throw new Error(`Invalid attribute: ${type}`)
        }

        try {
            await updateConsentMutation({
                variables: {
                    ConsentUpdateInput: {
                        consent: consent,
                        type: attr,
                    },
                },
            })
        } catch (error) {
            console.error("update consent mutation error: ", error)
            throw new Error("Error updating consent: " + error)
        }
    }

    // Feature flag for notification preferences, to be removed when feature is fully implemented
    // currently limiting it to PPOAdmin users, we have to do the check here instead of the route
    // because part of this page needs to render for all users for now
    const featureFlag = role === SubjectRole.PpoAdmin

    return (
        <>
            <Outlet />
            <div className={styles.pageContainer}>
                <div className={styles.bodyContainer}>
                    <div className={styles.userProfileContainer}>
                        <UserProfileSectionTitleRow title="User Information">
                            <Button
                                handleButtonClick={() => {
                                    navigate(
                                        USER_SETTINGS_EDIT_PROFILE_ABS_ROUTE
                                    )
                                }}
                                status="primary"
                                condition="default"
                            >
                                Edit Profile
                            </Button>
                        </UserProfileSectionTitleRow>
                        <UserProfileInfoRow>
                            <UserProfileInfoContainer
                                heading="First Name"
                                value={identity.firstName}
                            />
                            <UserProfileInfoContainer
                                heading="Last Name"
                                value={identity.lastName}
                            />
                            <UserProfileInfoContainer
                                heading="Role"
                                value={roleDisplay}
                            />
                        </UserProfileInfoRow>
                        {featureFlag && (
                            <>
                                <UserProfileSectionTitleRow title="Notification Preferences" />
                                <UserProfileNotificationContainer>
                                    <UserContactVerificationSection
                                        sectionTitle="Text"
                                        value={identity.phoneNumber}
                                        verified={form.smsVerified}
                                        attr="sms"
                                        separator
                                        onEditClick={() =>
                                            navigate(
                                                USER_SETTINGS_EDIT_PHONE_NUMBER_ABS_ROUTE
                                            )
                                        }
                                        disclaimerText={
                                            form.smsVerified
                                                ? undefined
                                                : "Verify your phone number to enable text notifications."
                                        }
                                    />
                                    {form.smsVerified &&
                                        identity.phoneNumber && (
                                            <div
                                                style={{
                                                    paddingBottom: "2rem",
                                                }}
                                            >
                                                <Checkbox
                                                    checkboxLabel={
                                                        "I want to receive text message alert notifications at this phone number."
                                                    }
                                                    checked={form.smsConsent}
                                                    handleCheckBoxClick={() =>
                                                        handleAllowNotificationsClick(
                                                            "sms"
                                                        )
                                                    }
                                                    disabled={
                                                        updateConsentMutationLoading
                                                    }
                                                />
                                            </div>
                                        )}
                                    <UserContactVerificationSection
                                        sectionTitle="Email"
                                        value={identity.email}
                                        verified={form.emailVerified}
                                        attr="email"
                                        separator
                                        onEditClick={() =>
                                            navigate(
                                                USER_SETTINGS_EDIT_EMAIL_ABS_ROUTE
                                            )
                                        }
                                        disclaimerText={
                                            form.emailVerified
                                                ? undefined
                                                : "Verify your email to enable email notifications."
                                        }
                                    />
                                    {form.emailVerified && identity.email && (
                                        <div
                                            style={{
                                                paddingBottom: "2rem",
                                            }}
                                        >
                                            <Checkbox
                                                checkboxLabel={
                                                    "I want to receive email alert notifications at this email address."
                                                }
                                                checked={form.emailConsent}
                                                handleCheckBoxClick={() =>
                                                    handleAllowNotificationsClick(
                                                        "email"
                                                    )
                                                }
                                                disabled={
                                                    updateConsentMutationLoading
                                                }
                                            />
                                        </div>
                                    )}
                                    <div className={styles.preferencesTitleRow}>
                                        <span>Do Not Disturb Hours</span>
                                        <Button
                                            handleButtonClick={() => {
                                                navigate(
                                                    USER_SETTINGS_EDIT_DND_ABS_ROUTE
                                                )
                                            }}
                                            status="primary"
                                            condition="default"
                                        >
                                            Edit Hours
                                        </Button>
                                    </div>
                                    <div className={styles.preferencesValue}>
                                        Hold notifications during a specific &
                                        recurring period (I.e. 10pm-6am M-F).
                                    </div>
                                    <div className={styles.disclaimerText}>
                                        By using the T-Rex Notification service,
                                        you agree to our use of these tools in
                                        accordance with our Privacy Policy and
                                        you agree to the terms of our Terms of
                                        Service.
                                    </div>

                                    <div
                                        className={styles.preferencesTitleRow}
                                        style={{ paddingTop: "2rem" }}
                                    >
                                        <span>Timezone</span>
                                        <Button
                                            handleButtonClick={() => {
                                                navigate(
                                                    USER_SETTINGS_EDIT_TIMEZONE_ABS_ROUTE
                                                )
                                            }}
                                            status="primary"
                                            condition="default"
                                        >
                                            Edit Timezone
                                        </Button>
                                    </div>
                                    <div className={styles.preferencesValue}>
                                        {getDisplayStringFromZoneInfo(
                                            identity.zoneInfo
                                        )}
                                    </div>
                                </UserProfileNotificationContainer>
                            </>
                        )}
                    </div>
                    <div className={styles.passwordContainer}>
                        <div className={styles.passwordSubContainer}>
                            <div className={styles.passwordResetHeading}>
                                Password Reset
                            </div>
                            <Button
                                handleButtonClick={handleForgotPassword}
                                status="primary"
                                condition="default"
                            >
                                Change Password
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserProfileSettings
