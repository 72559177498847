import { graphql } from "../../generated"

export const SUBSCRIPTION_FIELDS_FRAGMENT = graphql(`
    fragment SubscriptionFields on AlertSubscription {
        id
        email
        phone
        includeNewWells
        disturb
        silenceUntil {
            ...DateTimeFields
        }
        subscribedWells {
            ...WellIdentificationFields
        }
        subscriber {
            ...SubjectFields
        }
        createdBy {
            ...SubjectFields
        }
        alertRule {
            ...AlertRuleFields
        }
    }
`)
